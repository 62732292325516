import React, { useEffect } from "react";
import "./SignUpPage.scss";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { OAuthProvider } from "@nestcoinco/hyper-api-gateway-api-client";
import Lottie from "lottie-react";

import ImageGoogleSmallButtonIcon from "../../../images/google.svg";
import Gift from "../../../images/gift.svg";
import PrimaryActionButton from "../../../components/Buttons/PrimaryActionButton/PrimaryActionButton";
import { handleInitiateAuth } from "../../../api-clients/usersAuthClient";
import { renderHeaders } from "../../../utils/renderHeaders";
import {
  formatCurrency,
  getCurrentUrl,
  registerTrackingEvent,
} from "../../../utils/helpers";
import FacebookLogo from "../../../images/facebook-logo.svg";
import { PAGE_NAMES, TRACKING_EVENTS } from "../../../utils/constants";
import {
  clearStoredAuthCancelledError,
  getStoredAuthCancelledError,
  setStoredAuthProvider,
  getStoredSelectedEarning,
} from "../../../utils/storage";
import ImageHyperCoin from "../../../images/hyper-coin.svg";
import { showErrorToast } from "../../../utils/show-toast";
import ShieldAnimation from "../../../assets/animations/shield-with-hand.json";
import ImageShimmer from "../../../images/shimmer.svg";
import OnboardingHeader from "../../../components/Headers/OnboardingHeader/OnboardingHeader";

const isFacebookButtonEnabled =
  process.env.REACT_APP_ENABLE_FACEBOOK_BUTTON === "true";

function SignUpPage() {
  const storedEarning = getStoredSelectedEarning();

  const handleAuthClick = (provider: OAuthProvider) => {
    registerTrackingEvent({
      name:
        provider === OAuthProvider.GOOGLE
          ? TRACKING_EVENTS.CONTINUE_WITH_GOOGLE
          : TRACKING_EVENTS.CONTINUE_WITH_FACEBOOK,
      payload: { page_name: PAGE_NAMES.signup, url: getCurrentUrl() },
    });
    setStoredAuthProvider(provider);
    handleInitiateAuth(provider);
  };

  useEffect(() => {
    if (!getStoredAuthCancelledError()) return;
    showErrorToast("Unable to create account. Try again");

    return () => {
      clearStoredAuthCancelledError();
    };
  }, []);

  return (
    <>
      {renderHeaders({
        title:
          "Sign Up On Hyper Now To Start Earning | Hyper by Metaverse Magna",
        description:
          "Sign up now to play casual games ike candy crush, temple run, wordler with friends & frenemies for prize money",
      })}
      <Container className="MobileOnly position-relative pt-0 SignUpPage">
        <Col>
          <OnboardingHeader amountActive={2} />
        </Col>
        <Col className="imageGamepadContainer">
          <Lottie
            animationData={ShieldAnimation}
            loop={false}
            className="gamePadAnimation"
          />
        </Col>
        <h6 className="areYouReadyText">
          Are you ready to make{" "}
          <Image src={ImageHyperCoin} alt="hyper coin" className="coin" />
          {storedEarning ? formatCurrency(storedEarning) : ""}?
        </h6>
        <h1 className="createAccountText">
          Create a free account to get started!
        </h1>
        <Container className="d-flex justify-content-center signUpButtonContainer mt-0">
          <div className="mb-2 position-relative">
            <div className="text-white completeSignUpWelcomeContainer animated-background">
              <Image src={ImageShimmer} alt="shimmer" className="shimmer" />
              <span className="completeSignUpWelcomeText">
                <Image className="me-1" src={Gift} height={12} width={12} />{" "}
                +100% Bonus
              </span>
            </div>
            <PrimaryActionButton
              textColor={"#FFFFFF"}
              text="Continue with Google"
              onClick={() => handleAuthClick(OAuthProvider.GOOGLE)}
              leftImage={ImageGoogleSmallButtonIcon}
              imgClassName="googleSignInIcon mx-2"
              textClassName="authButtonText"
            />
          </div>
          {isFacebookButtonEnabled && (
            <PrimaryActionButton
              textColor={"#000000"}
              text="Continue with Facebook"
              onClick={() => handleAuthClick(OAuthProvider.FACEBOOK)}
              leftImage={FacebookLogo}
              imgClassName="googleSignInIcon mb-1 mx-2"
              style={{ backgroundColor: "#E9E9E9" }}
              textClassName="authButtonText"
            />
          )}
        </Container>
      </Container>
    </>
  );
}

export default SignUpPage;
