import { UAParser } from "ua-parser-js";

import { TRACKING_EVENTS } from "./constants";
import { showErrorToast, showSuccessToast } from "./show-toast";
import Logger from "./logger";
import { IpInfo } from "../types";

export function checkIsAndroidDevice() {
  const parser = new UAParser();
  const uaResult = parser.getResult();
  return !!uaResult.os.name?.includes("Android");
}

export function formatCurrency(value: number, currency?: "dollar" | "naira") {
  const symbol = currency === "dollar" ? "$" : currency === "naira" ? "₦" : "";
  if (isNaN(value)) {
    return "Invalid number";
  }
  // Convert number to a whole number and then to a string with commas
  const formattedNumber = Math.round(value)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${symbol}${formattedNumber}`;
}

type TrackingEventNameType =
  (typeof TRACKING_EVENTS)[keyof typeof TRACKING_EVENTS];
export function registerTrackingEvent({
  name,
  payload,
}: {
  name: TrackingEventNameType;
  payload?: any;
}) {
  (window as any)?.analytics?.track(name, payload);
}

export const getCurrentUrl = () => window.location.href;

type IdentityPayloadType = {
  name: string;
  username: string;
  email: string;
};

export function registerIdentityEvent(
  userId: string,
  payload: IdentityPayloadType
) {
  (window as any)?.analytics?.identify(userId, {
    ...payload,
    sign_up_type: "web",
  });
}

export function getTimeAgo(dateString?: string) {
  if (!dateString) return "";

  const inputDate = new Date(dateString);
  const now = new Date();

  const secondsAgo = Math.floor((now.getTime() - inputDate.getTime()) / 1000);
  const minutesAgo = Math.floor(secondsAgo / 60);
  const hoursAgo = Math.floor(minutesAgo / 60);

  if (secondsAgo < 60) {
    return `${secondsAgo} seconds ago`;
  } else if (minutesAgo < 60) {
    return `${minutesAgo} minutes ago`;
  } else {
    return `${hoursAgo} hours ago`;
  }
}

export async function copyToClipboard(text: string) {
  try {
    await navigator.clipboard.writeText(text);
    showSuccessToast("Copied successfully");
  } catch (error) {
    showErrorToast("Failed to copy");
  }
}

export function formatHprBalance(balance: number, threshold = 5): string {
  const balanceDigitCount = balance.toString().length;

  if (balanceDigitCount <= threshold) {
    return formatCurrency(balance);
  } else if (balanceDigitCount <= threshold + 1) {
    return `${(balance / 1000).toFixed(1)}K`;
  } else {
    return `${(balance / (1000 * 1000)).toFixed(1)}M`;
  }
}

export enum TimeUnit {
  SECOND = "second",
  MINUTE = "minute",
  HOUR = "hour",
  DAY = "day",
}

export function getTimeDifferenceToNow(dateString?: string):
  | {
      difference: number;
      unit: TimeUnit;
    }
  | undefined {
  if (!dateString) return;

  const inputDate = new Date(dateString);
  const now = new Date();

  const seconds = Math.floor((inputDate.getTime() - now.getTime()) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.ceil(hours / 24);

  if (Math.abs(seconds) < 60) {
    return { difference: seconds, unit: TimeUnit.SECOND };
  } else if (Math.abs(minutes) < 60) {
    return { difference: minutes, unit: TimeUnit.MINUTE };
  } else if (Math.abs(hours) < 24) {
    return { difference: hours, unit: TimeUnit.HOUR };
  } else {
    return { difference: days, unit: TimeUnit.DAY };
  }
}

export function getTimeUntilFutureDate(
  futureDateStr: string,
  showDays?: boolean
): string | undefined {
  const futureDate = new Date(futureDateStr);
  const now = new Date();

  const diffInMs = futureDate.getTime() - now.getTime();

  if (diffInMs < 0) return undefined; // time is in past

  const diffInSeconds = Math.floor(diffInMs / 1000);
  const seconds = diffInSeconds % 60;

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const minutes = diffInMinutes % 60;

  const diffInHours = Math.floor(diffInMinutes / 60);
  const hours = diffInHours % 24;

  const days = Math.floor(diffInHours / 24);

  const formatNumber = (num: number) => (num < 10 ? `0${num}` : `${num}`);

  if (showDays) {
    return `${formatNumber(days)}d:${formatNumber(hours)}h:${formatNumber(
      minutes
    )}m:${formatNumber(seconds)}s`;
  } else {
    const totalHours = days * 24 + hours;
    return `${formatNumber(totalHours)}:${formatNumber(minutes)}:${formatNumber(
      seconds
    )}`;
  }
}

export const getEndOfDay = (): string => {
  const now = new Date();

  return new Date(
    now.getUTCFullYear(),
    now.getUTCMonth(),
    now.getUTCDate() + 1
  ).toISOString();
};

export const fetchIpInfo = async (): Promise<IpInfo | undefined> => {
  try {
    return (await (
      await fetch("https://ipinfo.io/json")
    ).json()) as Promise<IpInfo>;
  } catch (e) {
    Logger.error(e);
  }
};

export function checkIsScrolledToBottom(): boolean {
  const scrollTop = document.documentElement.scrollTop;
  const documentHeight = document.documentElement.scrollHeight;
  const windowHeight = window.innerHeight;

  return scrollTop + windowHeight >= documentHeight;
}

export function checkIsMobile(screenWidth?: number) {
  if (typeof window === "undefined") return false;

  const { innerWidth } = window;
  const MOBILE_SCREEN_THRESHOLD = 830;
  const parser = new UAParser();
  const uaResult = parser.getResult();

  return !!(
    (screenWidth || innerWidth) <= MOBILE_SCREEN_THRESHOLD ||
    uaResult.device.type === "mobile" ||
    uaResult.device.type === "tablet" ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/Android/i)
  );
}

export function checkBrowser() {
  if (typeof window === "undefined") return "";
  const parser = new UAParser();
  return parser.getBrowser().name;
}

export function createAppIntent(
  baseUrl: string,
  path: string,
  paramsObj?: Record<string, string>
) {
  const generateParamsString = (params: Record<string, string>): string => {
    let result: string = "";
    Object.entries(params).forEach((param, index, paramsArr) => {
      result += `${param[0]}=${param[1]}`;
      if (index === paramsArr.length - 1) return;
      result += "&";
    });
    return result;
  };

  const paramsStr = paramsObj ? generateParamsString(paramsObj) : "";
  return `intent://${baseUrl}${path}?${paramsStr}#Intent;scheme=https;action=android.intent.action.VIEW;end;`;
}

export enum AppType {
  PWA = "webapp",
  ANDROID = "play",
}

export function openApp(customPath?: string, appType = AppType.PWA) {
  const { hostname: baseUrl, pathname: path } = window.location;
  const paramsObj = appType === AppType.PWA ? { source: "pwa" } : undefined;
  return window.open(
    createAppIntent(baseUrl, customPath || path, paramsObj),
    "_blank"
  );
}
