import { FileType } from "@nestcoinco/hyper-api-gateway-api-client";

const DEFAULT_APK = {
  id: "",
  url: "https://hyper-production.mvm.gg/apk/hyper-app-241.apk",
  type: FileType.APK,
  isPublic: true,
  version: 241,
};

export default DEFAULT_APK;
