import {
  FilesFilesClient,
  FileType,
} from "@nestcoinco/hyper-api-gateway-api-client";

import apiConfig from "../config/api-config";
import defaultAPK from "../config/default-apk";
import { axiosInstance } from "../config/axios-instance";

const fileClient = new FilesFilesClient(apiConfig, undefined, axiosInstance);

const fetchAPKs = async () => {
  try {
    const fileList = (
      await fileClient.getFilesInfo(1, 1, undefined, FileType.APK)
    ).data;
    return fileList.records.length ? fileList.records : [defaultAPK];
  } catch {
    return;
  }
};

export { fetchAPKs };
