import React, { ComponentProps, PropsWithChildren } from "react";
import "./FixedFooter.scss";

function FixedFooter({
  children,
  className = "",
  ...restProps
}: PropsWithChildren<ComponentProps<"footer">>) {
  return (
    <footer className={`MobileOnly fixed-footer ${className}`} {...restProps}>
      {children}
    </footer>
  );
}

export default FixedFooter;
