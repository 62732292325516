import React, { ComponentProps } from "react";

function GameControllerIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_608_142760)">
        <path d="M22.2542 6.86265C19.0843 1.65458 14.309 5.2779 14.309 5.2779C13.9993 5.51196 13.4282 5.7044 13.0402 5.7053L11.7611 5.7062C11.3731 5.70664 10.802 5.5142 10.4928 5.28013C10.4928 5.28013 5.71704 1.65592 2.54664 6.86399C-0.622867 12.0703 0.654424 17.7402 0.654424 17.7402C0.878196 19.1285 1.61575 20.0549 3.00761 19.9385C4.39545 19.8226 7.40786 16.1998 7.40786 16.1998C7.6567 15.9012 8.17809 15.6573 8.56521 15.6573L16.2334 15.656C16.621 15.656 17.1419 15.8999 17.3903 16.1984C17.3903 16.1984 20.4032 19.8213 21.7933 19.9372C23.1833 20.0536 23.9227 19.1263 24.1451 17.7389C24.1447 17.7389 25.4242 12.0698 22.2542 6.86265ZM9.51848 11.1018H7.87421V12.6865C7.87421 12.6865 7.52602 12.9524 6.98717 12.9457C6.44923 12.9376 6.20576 12.6557 6.20576 12.6557V11.1022H4.65278C4.65278 11.1022 4.459 10.9035 4.40708 10.3803C4.35606 9.85717 4.62146 9.43424 4.62146 9.43424H6.26618V7.78996C6.26618 7.78996 6.60452 7.6087 7.091 7.62258C7.57793 7.63779 7.93462 7.82084 7.93462 7.82084L7.92791 9.43379H9.48089C9.48089 9.43379 9.7521 9.78198 9.77448 10.2027C9.79685 10.6238 9.51848 11.1018 9.51848 11.1018ZM17.0605 12.9081C16.3404 12.9081 15.7599 12.3258 15.7599 11.6066C15.7599 10.8861 16.3404 10.3052 17.0605 10.3052C17.7775 10.3052 18.3624 10.8861 18.3624 11.6066C18.3624 12.3263 17.7775 12.9081 17.0605 12.9081ZM17.0605 9.32727C16.3404 9.32727 15.7599 8.74547 15.7599 8.02626C15.7599 7.30572 16.3404 6.72481 17.0605 6.72481C17.7775 6.72481 18.3624 7.30572 18.3624 8.02626C18.3624 8.74591 17.7775 9.32727 17.0605 9.32727ZM20.3016 11.2495C19.5815 11.2495 19.001 10.6677 19.001 9.94802C19.001 9.22792 19.5815 8.64656 20.3016 8.64656C21.0186 8.64656 21.6035 9.22792 21.6035 9.94802C21.6035 10.6677 21.0186 11.2495 20.3016 11.2495Z" />
      </g>
      <defs>
        <clipPath id="clip0_608_142760">
          <rect
            width={24}
            height={24}
            fill="white"
            transform="translate(0.400024)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default GameControllerIcon;
