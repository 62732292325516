import { SocialsChallengeInvitationsClient } from "@nestcoinco/hyper-api-gateway-api-client";

import apiConfig from "../config/api-config";
import { axiosInstance } from "../config/axios-instance";

const socialsChallengesClient = new SocialsChallengeInvitationsClient(
  apiConfig,
  undefined,
  axiosInstance
);

const handleGetChallengeInvitationDetails = async (inviteId: string) => {
  try {
    const { data } = await socialsChallengesClient.getInvitationDetail(
      inviteId
    );
    return data;
  } catch {
    return;
  }
};

export { handleGetChallengeInvitationDetails };
