import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import PaymentStatus, { PaymentStatusType } from '../../components/Payments/PaymentStatus/PaymentStatus';
import EventDispatcher from '../../utils/event-dispatcher';
import { EventTypes } from '../../utils/constants';
import { AppIntentValues } from '../../utils/constants/events-types';

function TopupFailed() {
  useEffect(() => {
    EventDispatcher.dispatch(EventTypes.PAYMENTS.FAILED, { message: "Payment failed" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Topup Failed 😢😔 | Hyper by Metaverse Magna</title>
        <meta name="og:title" content="Topup Failed 😢😔 | Hyper by Metaverse Magna"/>
      </Helmet>

      <PaymentStatus
        type={PaymentStatusType.DONE}
        title="Payment failed"
        message="Your payment was unsuccessful"
        eventType={EventTypes.PAYMENTS.FAILED}
        navigateUrl={AppIntentValues.PAYMENT_FAILED}
      />
    </>
  )
}

export default TopupFailed;