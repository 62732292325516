import React, { PropsWithChildren, createContext, useContext, useState } from "react";
import {
  ConfirmPaymentResponse,
} from "@nestcoinco/hyper-api-gateway-api-client";

export interface PaymentConfirmationContextValue {
  topupAmount: ConfirmPaymentResponse["amount"];
  updateTopupAmount: (amount: ConfirmPaymentResponse["amount"]) => void;
}

const PaymentConfirmationContext = createContext<PaymentConfirmationContextValue>({
  topupAmount: 0,
  updateTopupAmount: () => null,
});

export function usePaymentConfirmationContext() {
  const { topupAmount, updateTopupAmount } = useContext(PaymentConfirmationContext);
  return { topupAmount, updateTopupAmount };
}

export default function PaymentConfirmationProvider({ children }: PropsWithChildren) {
  const [topupAmount, setTopupAmount] = useState<ConfirmPaymentResponse["amount"]>(0);
  const updateTopupAmount = (amount: ConfirmPaymentResponse["amount"]) => {
    setTopupAmount(amount);
  }

  return (
    <PaymentConfirmationContext.Provider value={{topupAmount, updateTopupAmount}}>
      {children}
    </PaymentConfirmationContext.Provider>
  )
}
