import { UsersAvatarsClient } from "@nestcoinco/hyper-api-gateway-api-client";

import apiConfig from "../config/api-config";
import { axiosInstance } from "../config/axios-instance";

const avatarsClient = new UsersAvatarsClient(
  apiConfig,
  undefined,
  axiosInstance
);

const handleFetchAvatars = async () => {
  try {
    const avatars = (await avatarsClient.getAvailableAvatars()).data;
    return avatars;
  } catch {
    return;
  }
};

export { handleFetchAvatars };
