import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Lottie from "lottie-react";

import "./WelcomePage.scss";
import PrimaryActionButton from "../../../components/Buttons/PrimaryActionButton/PrimaryActionButton";
import { getStoredUserProfile } from "../../../utils/storage";
import ShieldAnimation from "../../../assets/animations/shield-with-hand.json";
import HyperTextIcon from "../../../images/group_hyper_word_icon.svg";
import FixedFooter from "../../../components/FixedFooter/FixedFooter";
import { ROUTES } from "../../../utils/constants";

function WelcomePage() {
  const navigate = useNavigate();
  const username = getStoredUserProfile()?.username;

  return (
    <Container className="MobileOnly position-relative WelcomePage">
      <Col>
        <Link to="/">
          <Image className="signUpToHyperTextImage" src={HyperTextIcon} />
        </Link>{" "}
      </Col>
      <Col className="imageGamepadContainer">
        <Lottie
          animationData={ShieldAnimation}
          loop={false}
          className="gamePadAnimation"
        />
      </Col>
      <h6 className="welcomeText">
        {username},<br /> Welcome to hyper!
      </h6>
      <h1 className="battleText">
        Battle with friends, have fun, and start making real money gaming.
      </h1>
      <FixedFooter>
        <Container className="d-flex justify-content-center goButtonContainer mt-0">
          <PrimaryActionButton
            textColor={"#FFFFFF"}
            text="Let's go!"
            textClassName="goButtonText"
            onClick={() =>
              navigate(
                `${ROUTES.ROOT.ONBOARDING}/${ROUTES.ONBOARDING.FIND_FRIENDS}`
              )
            }
          />
        </Container>
      </FixedFooter>
    </Container>
  );
}

export default WelcomePage;
