import { Button, Image } from "react-bootstrap";
import React from "react";

import "./CircularButton.scss";

type ICircularButtonProps = {
  color?: string;
  onClick?: () => void;
  disabled?: boolean;
  image: string;
  imageClass?: string;
  buttonClass: string;
};
function CircularButton({
  color,
  onClick,
  disabled,
  image,
  imageClass,
  buttonClass,
}: ICircularButtonProps) {
  const ButtonStyle = {
    background: color,
  };

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      className={` ${buttonClass}  CircularButton`}
      style={ButtonStyle}
    >
      <Image
        className={` ${imageClass}  circular-img`}
        src={image}
        alt="circular_image"
      />
    </Button>
  );
}

export default CircularButton;
