import React, {useEffect, useState} from 'react';
import Container from "react-bootstrap/Container";
import { Helmet } from 'react-helmet-async';
import  { useSearchParams } from "react-router-dom";
import {
  ReferrerInfo,
  ReferrersReferralsClient,
  FilesFilesClient,
  FileInfo,
  FileType
} from "@nestcoinco/hyper-api-gateway-api-client";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import Safe from "../../images/safe.svg";
import Win from "../../images/win.png";
import MetaImage from "../../images/meta-og-image.png";
import QrCode from "../../components/QrCode/QrCode";
import DownloadStep from "../../components/DownloadStep/DownloadStep";
import StickyHeader from "../../components/StickyHeader/StickyHeader";
import DownloadBtn from "../../components/DownloadBtn/DownloadBtn";
import Collapsible from "../../components/Collapsible/Collapsible";
import DontForget from '../../components/DontForget/DontForget';
import Footer from "../../components/Footer/Footer";
import apiConfig from "../../config/api-config";
import defaultAPK from "../../config/default-apk";
import steps from "../../contents/steps";
import faqs from "../../contents/faqs";
import "./DownloadApp.scss";

const DEFAULT_COPY = "Play fun games & win real money.";
const DEFAULT_SUBCOPY = "Play battle games and invite friends to win rewards";

function DownloadApp() {
  const [metaTitle, setMetaTitle] = useState<string>("Battle Friends in Fun Games & Make Money | Hyper by Metaverse Magna");
  const [apks, setApks] = useState<Array<FileInfo>>([]);
  const [loadingRef, setLoadingRef] = useState<boolean>(false);
  const [referrerInfo, setReferrerInfo] = useState<ReferrerInfo>();
  const [showDontForget, setShowDontForget] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const referrerId = searchParams.get('referrer');

  const fetchReferrerInfo = async (id: string | null) => {
    if (id) {
      try {
        setLoadingRef(true);
        const referrerClient = new ReferrersReferralsClient(apiConfig);
        const rI = (await referrerClient.getReferrerInfo(id)).data;
        setReferrerInfo(rI);
        setMetaTitle(`${rI.firstName || '@' + rI.username} is challenging you to a fun game | Hyper by Metaverse Magna`);
      } finally {
        setLoadingRef(false);
      }
      return;
    }

  };

  const fetchAPKs = async () => {
    const fileClient = new FilesFilesClient(apiConfig);
    const fileList = (await fileClient.getFilesInfo(1, 1, undefined, FileType.APK)).data;
    setApks(fileList.records.length ? fileList.records : [defaultAPK]);
  };


  useEffect(() => {
    fetchReferrerInfo(referrerId).catch();
  }, [referrerId]);

  useEffect(() => {
    fetchAPKs().catch();
  }, [referrerId]);

  const showDontForgetModal = () => {
    if (referrerInfo) {
      setShowDontForget(true);
    }
  }

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content="Battle friends & frenemies in fun games ike candy crush, temple run, and many more. Win your friends, to win the prize pool. Download Hyper Android APK to get started"/>
        <meta name="og:title" content="Download Hyper, Play Casual Games, Win daily prize pools of $1K"/>
        <meta name="og:description" content="Battle friends & frenemies in fun casual games like candy crush, temple run, wordler to win $1K in daily prize pools. Download Hyper"/>
        <meta name="og:image" content={MetaImage}/>
      </Helmet>
      <Container fluid className="DownloadApp">
        <StickyHeader apk={apks[0]} onDownloadClicked={showDontForgetModal} disableDownload={loadingRef || !apks.length}/>
        <Row as="header" className="Header text-white text-center text-md-start">
          <Col md={6} lg={7} className="pt-5 pb-3 p-lg-5">
            <Col className="px-0 pt-3 pt-md-5 d-flex flex-column h-100 justify-content-center">
              <Col as="h1" className="heading text-uppercase flex-grow-0 fs-1 px-0 px-sm-3 py-3 p-lg-4">
                {DEFAULT_COPY}
              </Col>
              <Col className="px-0 px-sm-3 px-lg-4 py-2 flex-column flex-md-grow-0">
                <p className="fs-4 py-xl-3">
                  {!referrerInfo?.code && DEFAULT_SUBCOPY}
                  {referrerInfo?.code && <span>Use code {referrerInfo.code} to get <span className="hundred-percent-bonus-fire">100% bonus 🔥</span> when you sign up</span>}
                  </p>
                <DownloadBtn file={apks[0]} onClick={showDontForgetModal} disabled={loadingRef || !apks.length}/>
              </Col>
              <Col className="px-0 px-sm-3 px-lg-4 py-2 flex-grow-0 d-none d-md-flex flex-column flex-md-row-reverse align-items-center justify-content-md-end">
                <div className="px-md-3 pb-3 pb-md-0 fs-5 text-center text-md-start">
                  Scan the QR code to download the <br className="d-none d-md-inline"/> Hyper App
                </div>
                <QrCode value={(apks.length && apks[0].url) || window.location.href} className="py-4" />
              </Col>
            </Col>
          </Col>
          <Col md={6} lg={5} className="p-3 px-lg-5 text-md-center justify-content-center transparent">
            <Col className="py-3 py-xl-5 d-flex flex-column justify-content-center h-100 px-xl-5">
              <Col as="h4" className="pb-4 heading flex-grow-0 px-md-5 pb-xl-5">
                Flex your skills and beat opponents
              </Col>
              <Col className="flex-grow-0">
                <Image src={Win} className="win"/>
              </Col>
            </Col>
          </Col>
        </Row>
        <Row className="section1-container">
          <Col>
            <Container as="section" className="py-5 section1">
              <Col as="h2" className="text-uppercase px-lg-5 py-lg-3">
                Follow these steps to sign up & claim your 💎 GEM rewards on Hyper
              </Col>
              <Col className="py-4">
                <Col className="safe d-inline-flex align-items-center m-auto px-3 py-2 px-md-4 py-md-3">
                  <Image src={Safe} alt="100% Safe and Secure"/>
                  <h5 className="mb-0 d-inline-block px-1 px-md-3">100% Safe and Secure</h5>
                </Col>
              </Col>
              <Col>
                {
                  steps.map((s:any, index:number ) => (
                    <DownloadStep key={`step-${index}`} title={s.title} image={s.image} step={index + 1} content={s.content}/>
                  ))
                }
              </Col>
            </Container>
          </Col>
        </Row>
        <Row>
          <Col>
            <Container className="px-0">
              <Col className="text-center px-3 pt-5 pb-3">
                <h2 className="pb-3">FAQs</h2>
                <p>Need more information? Check out our Frequently Asked Questions.</p>
              </Col>
              <Col className="pb-5">
                <Collapsible data={faqs}/>
              </Col>
            </Container>
          </Col>
        </Row>
        <Footer/>
        {
          referrerInfo ? <DontForget show={showDontForget} setShow={setShowDontForget} code={referrerInfo.code} username={referrerInfo.username}/> : null
        }
      </Container>
    </>
  );
}

export default DownloadApp;
