export const TRACKING_EVENTS = {
  LANDING_PAGE_VISIT: "Landing_page_visit",
  WEB_DOWNLOAD: "Web_download",
  DOWNLOAD_STARTED: "download_started",
  INITIATE_WEB_SIGNUP: "Initiate_web_signup",
  CONTINUE_WITH_GOOGLE: "Continue_with_google",
  WEB_SIGN_UP_WITH_GOOGLE: "Web_Sign_up_with_google",
  SUCCESSFUL_WEB_SIGNUP: "Successful_web_signup",
  WEB_SELECT_COUNTRY: "Web_Select_Country",
  WEB_CHOOSE_REWARD: "Web_Choose_Reward",
  WEB_SUBMIT_REFERRAL_CODE: "Web_Submit_referral_code",
  SKIP_WEB_REFERRAL: "Skip_Web_Referral",
  CREATE_WEB_USERNAME: "Create_web_username",
  COMPLETE_WEB_ONBOARDING: "Complete_web_onboarding",
  CONTINUE_WITH_FACEBOOK: "Continue_with_facebook",
  WEB_SIGN_UP_WITH_FACEBOOK: "Web_sign_up_with_facebook",
  SUCCESSFUL_WEB_LOGIN: "Successful_web_login",
  WEB_LOG_IN_WITH_GOOGLE: "Web_Log_in_with_google",
  WEB_LOG_IN_WITH_FACEBOOK: "Web_log_in_with_facebook",
} as const;
