import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OAuthProvider } from "@nestcoinco/hyper-api-gateway-api-client";

import "./LoggingInPage.scss";
import ImageGoogleSmallButtonIcon from "../../../images/google.svg";
import DefaultOnboardingStatusPage from "../../ReusablePages/DefaultOnboardingStatusPage/DefaultOnboardingStatusPage";
import { handleVerifyAuth } from "../../../api-clients/usersAuthClient";
import {
  getStoredAuthFlow,
  getStoredAuthProvider,
  setStoredAuthCancelledError,
  setStoredUser,
} from "../../../utils/storage";
import FacebookLogo from "../../../images/facebook-logo.svg";
import { AuthFlow, ROUTES } from "../../../utils/constants";
import GameControllerIcon from "../../../components/icons/GameControllerIcon";
import LoadingDots from "../../../components/LoadingDots/LoadingDots";

const basePath = ROUTES.ROOT.ONBOARDING;
const fallbackPath = `${basePath}/${ROUTES.ONBOARDING.SIGN_UP}`;

function LoggingInPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [token, setToken] = useState<string>();
  const base64Data = queryParams.get("data");
  const base64Error = queryParams.get("error");
  const authFlow = getStoredAuthFlow();
  const authFlowText =
    authFlow === AuthFlow.LOGIN ? "Logging in" : "Signing up";
  const titleText = (
    <span className="d-flex">
      {`${authFlowText} with ${
        getStoredAuthProvider() === OAuthProvider.FACEBOOK
          ? "Facebook"
          : getStoredAuthProvider() === OAuthProvider.GOOGLE
          ? "Google"
          : ""
      } `}{" "}
      <LoadingDots dotStyles={{ background: "white" }} />
    </span>
  );

  useEffect(() => {
    if (!base64Error) return;
    setStoredAuthCancelledError(JSON.parse(atob(base64Error)));
    navigate(fallbackPath);
  }, [base64Error, navigate]);

  useEffect(() => {
    function getToken(data: string) {
      const { idToken } = JSON.parse(atob(data));
      idToken && setToken(idToken);
    }
    base64Data && getToken(base64Data);
  }, [base64Data]);

  useEffect(() => {
    const authProvider = getStoredAuthProvider();
    if (!authProvider) {
      navigate(fallbackPath);
      return;
    }
    if (!token) return;
    const verifyAuth = async () => {
      const user = await handleVerifyAuth(token, authProvider);
      if (!user) {
        navigate(fallbackPath);
        return;
      }
      setStoredUser(user);
      const redirectPath = user.isReturning
        ? `${basePath}/${ROUTES.ONBOARDING.WELCOME_BACK}`
        : `${basePath}/${ROUTES.ONBOARDING.CONGRATULATIONS}`;
      navigate(redirectPath);
    };
    verifyAuth();
  }, [navigate, token]);

  return (
    <div>
      <DefaultOnboardingStatusPage
        titleText={titleText}
        secondaryText="Please wait a moment while we create your account"
        primaryImage={
          getStoredAuthProvider() === OAuthProvider.FACEBOOK
            ? FacebookLogo
            : ImageGoogleSmallButtonIcon
        }
        secondaryIcon={
          <GameControllerIcon className="spinning-controller" width={40} />
        }
        stayOnPage
      />
    </div>
  );
}

export default LoggingInPage;
