import React, { useState, useCallback, useEffect, useRef } from "react";
import { Image } from "react-bootstrap";
import { LatestWinnersList } from "@nestcoinco/hyper-api-gateway-api-client";

import "./LastWinFlipper.scss";
import HyperCoin from "../../components/HyperCoin/HyperCoin";
import { handleGetLatestWinners } from "../../api-clients/tournamentsExternalsClient";
import { getTimeAgo, checkIsScrolledToBottom } from "../../utils/helpers";

const ITEM_FLIP_INTERVAL = 4000;
const WINNERS_REFETCH_INTERVAL = 40000;

function LastWinFlipper() {
  const [indexOfItemInView, setIndexOfItemInView] = useState(0);
  const [latestWinners, setLatestWinners] = useState<LatestWinnersList>();
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const intervalIdRef = useRef<NodeJS.Timeout>();
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  const getWinners = useCallback(async () => {
    const winners = await handleGetLatestWinners();
    setLatestWinners(winners);

    const scrollCallback = () => {
      if (checkIsScrolledToBottom()) {
        setHasScrolledToBottom(true);
      } else {
        setHasScrolledToBottom(false);
      }
    };

    window.addEventListener("scroll", scrollCallback);

    return () => {
      window.removeEventListener("scroll", scrollCallback);
    };
  }, []);

  useEffect(() => {
    clearTimeout(timeoutIdRef.current);

    const timeoutId = setTimeout(() => {
      if (
        latestWinners?.records &&
        indexOfItemInView === latestWinners?.records?.length - 1
      ) {
        setIndexOfItemInView(0);
      } else {
        latestWinners?.records && setIndexOfItemInView((prev) => prev + 1);
      }
      timeoutIdRef.current = timeoutId;
    }, ITEM_FLIP_INTERVAL);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [indexOfItemInView, latestWinners?.records]);

  useEffect(() => {
    clearInterval(intervalIdRef.current);
    getWinners();

    const intervalId = setInterval(() => {
      getWinners();
    }, WINNERS_REFETCH_INTERVAL);

    intervalIdRef.current = intervalId;

    return () => {
      clearInterval(intervalId);
    };
  }, [getWinners]);

  if (!latestWinners) return null;

  const currentWinner = latestWinners.records[indexOfItemInView];

  return (
    <div
      className="last-win-flipper  d-flex justify-content-center align-item-center"
      style={{ bottom: hasScrolledToBottom ? undefined : 0 }}
    >
      <div className="item-box">
        <div className="item">
          <div>
            <p className="img-box">
              <span>
                <Image alt="" src={currentWinner.avatarUrl} />
              </span>
            </p>
          </div>
          <div>
            <p className="winner-text mb-1">
              @{currentWinner?.username} just won <HyperCoin width={16} />{" "}
              {currentWinner.tournament?.rewardAmount} HPR
            </p>
            <p className="time">
              {getTimeAgo(currentWinner.tournament?.endDate)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LastWinFlipper;
