import React from "react";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

function NotFound(props: any) {
  return (
    <Col className="d-flex flex-column text-center h-100 justify-content-center px-3 py-5 align-items-center">
      <Col as="h1">404</Col>
      <Col as="h5" className="col-md-6 py-4">Oops! The page you are trying to visit does not exist.</Col>
      <Link to="/">Go Home</Link>
    </Col>
  );
}

export default NotFound;