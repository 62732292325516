import {
  UsersAccountsClient,
  OnboardingState,
  UpdateUserDto,
} from "@nestcoinco/hyper-api-gateway-api-client";

import apiConfig from "../config/api-config";
import { axiosInstance } from "../config/axios-instance";

const userAccount = new UsersAccountsClient(
  apiConfig,
  undefined,
  axiosInstance
);

const handleGetProfile = async () => {
  try {
    const userProfile = (await userAccount.getActiveUserProfile()).data;
    return userProfile;
  } catch {
    return;
  }
};

const handleUpdateUsername = async (username: string) => {
  try {
    const { data } = await userAccount.changeUsername({ username });
    return data;
  } catch {
    return;
  }
};

const handleUploadAvatar = async (avatar: File, label?: string) => {
  try {
    const profile = (await userAccount.uploadUserAvatar(avatar, label)).data;
    return profile;
  } catch {
    return;
  }
};

const handleUpdateUserCountry = async (countryCode: string) => {
  try {
    const { data } = await userAccount.registerCountry({ countryCode });
    return data;
  } catch {
    return;
  }
};

const handleUpdateUser = async (userInfo: UpdateUserDto) => {
  try {
    const user = (await userAccount.updateUser(userInfo)).data;
    return user;
  } catch {
    return;
  }
};

const handleGetOnboardingState = async () => {
  try {
    const onboardingState = (await userAccount.getUserOnboardingState()).data;
    return onboardingState;
  } catch {
    return;
  }
};

const handleUpdateOnboardingState = async (
  onboardingState: OnboardingState
) => {
  try {
    const newOnboardingState = (
      await userAccount.updateUserOnboardingState(onboardingState)
    ).data;
    return newOnboardingState;
  } catch {
    return;
  }
};

export {
  handleGetProfile,
  handleUpdateUsername,
  handleUploadAvatar,
  handleUpdateUserCountry,
  handleUpdateUser,
  handleGetOnboardingState,
  handleUpdateOnboardingState,
};
