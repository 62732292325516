import React, {
  createContext,
  type FC,
  type ReactNode,
  useContext,
  useMemo,
  useState,
} from "react";
import { UsersSvcUser } from "@nestcoinco/hyper-api-gateway-api-client";

interface IUsernamePageContext {
  profile: UsersSvcUser | null;
  setProfile: (user: UsersSvcUser) => void;
}

const UsernamePageContext = createContext<IUsernamePageContext>(
  {} as IUsernamePageContext
);

const UsernamePageContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [profile, setProfile] = useState<UsersSvcUser | null>(null);
  const value = useMemo(() => ({ profile, setProfile }), [profile]);

  return (
    <UsernamePageContext.Provider value={value}>
      {children}
    </UsernamePageContext.Provider>
  );
};

const useUsernamePageContext = () => {
  const usernamePageContext = useContext(UsernamePageContext);
  if (usernamePageContext === undefined) {
    throw new Error(
      "useUsernamePageContext must be used within an UsernamePageContext Provider"
    );
  }

  return usernamePageContext;
};

export { UsernamePageContextProvider, useUsernamePageContext };
