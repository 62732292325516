import {
  UsersAuthsClient,
  OAuthProvider,
} from "@nestcoinco/hyper-api-gateway-api-client";

import apiConfig from "../config/api-config";
import { axiosInstance } from "../config/axios-instance";
import authConfig from "../config/auth-config";

const userAuth = new UsersAuthsClient(apiConfig, undefined, axiosInstance);

const handleVerifyAuth = async (
  idToken: string,
  provider: OAuthProvider
) => {
  try {
    const { data: userInfo } = await userAuth.verifyOauth({
      idToken,
      provider,
    });
    return userInfo;
  } catch {
    return;
  }
};

const handleInitiateAuth = async (provider: OAuthProvider) => {
  try {
    const redirectUrl = `${authConfig.baseUrl}${authConfig.onboardingRedirectPath}`;
    const { initializationUrl } = (
      await userAuth.initOauth(
        provider === OAuthProvider.GOOGLE
          ? OAuthProvider.GOOGLE
          : OAuthProvider.FACEBOOK,
        {
          redirectUrl,
        }
      )
    ).data;
    if (!initializationUrl) {
      window.location.href = `${authConfig.baseUrl}${authConfig.fallbackRedirectPath}`;
      return;
    }
    window.location.href = initializationUrl;
  } catch {
    return;
  }
};

export { handleVerifyAuth, handleInitiateAuth };
