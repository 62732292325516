import React, { useEffect, useRef, useState } from "react";
import { Container, Col, Image } from "react-bootstrap";

import ExplainerImage from "../../../images/download-tutorial.gif";
import WhatsappIcon from "../../../images/whatsapp.svg";
import PrimaryActionButton from "../../../components/Buttons/PrimaryActionButton/PrimaryActionButton";
import "./WelcomeBackPage.scss";
import { LINKS } from "../../../utils/constants";
import Lightbox from "../../../components/Lightbox/Lightbox";
import { handleUpdateOnboardingState } from "../../../api-clients/userAccountClient";
import ImageGamePadMedal from "../../../images/medal-gamepad.svg";
import { getStoredUser } from "../../../utils/storage";

const videoTitle = "How To Install Hyper Gaming App";

function WelcomeBackPage() {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const hasWatchedtutorialRef = useRef(false);
  const storedUser = getStoredUser()?.user;

  useEffect(() => {
    if (!isLightboxOpen || hasWatchedtutorialRef.current) return;

    (async () => {
      await handleUpdateOnboardingState({ watchedTutorial: true });
      hasWatchedtutorialRef.current = true;
    })();
  }, [isLightboxOpen]);

  return (
    <>
      <Container className="MobileOnly welcome-back-page px-0">
        <div className="welcome-back-content-container">
          <Col className="welcome-back-content">
            <Image
              src={ImageGamePadMedal}
              alt="game controller"
              className="game-pad-img"
            />
            <h1 className="welcome-text text-white">
              Welcome back,
              <br />
              {storedUser?.username}
            </h1>
          </Col>
          <p className="reward-instructions-text text-white">
            Update and open the Hyper app from your download folder and start
            making real money gaming.
          </p>
          <Image
            src={ExplainerImage}
            alt="instal_explainer_image"
            className="install-explainer-image"
          />
        </div>

        <Container className="congrats-footer-container MobileOnly">
          <p className="need-help-installing-text">Need help installing?</p>
          <Col className="button-row">
            <PrimaryActionButton
              textColor={"#FFFFFF"}
              text="WATCH TUTORIAL"
              textClassName="button-text"
              buttonClassName="watch-tutorial-button"
              onClick={() => setIsLightboxOpen(true)}
            />
            <PrimaryActionButton
              as="a"
              href={LINKS.SOCIAL.WHATSAPP}
              color={"#27B43E"}
              textColor={"#FFFFFF"}
              text="GET HELP"
              leftImage={WhatsappIcon}
              imgClassName="icon-class"
              textClassName="button-text"
              buttonClassName="get-help-button"
            />
          </Col>
        </Container>
        <Lightbox
          src={LINKS.VIDEO.HOW_TO_INSTALL_HYPER}
          title={videoTitle}
          isOpen={isLightboxOpen}
          setIsOpen={setIsLightboxOpen}
        />
      </Container>
    </>
  );
}

export default WelcomeBackPage;
