import React from "react";
import Image from "react-bootstrap/Image";
import toast from "react-hot-toast";

import ImageCheckmark from "../images/checkmark-brand.svg";
import "../styles/toast.scss";

const showSuccessToast = (message: string, style?: React.CSSProperties) =>
  toast(message, {
    className: "hyper-toast",
    icon: <Image src={ImageCheckmark} alt="checkmark" />,
    style,
  });

const showErrorToast = (message: string, style?: React.CSSProperties) =>
  toast(message, {
    className: "hyper-toast",
    style,
  });

export { showSuccessToast, showErrorToast };