import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import socials from "../../contents/socials";
import "./Footer.scss";
import { LINKS } from "../../utils/constants";

function Footer () {
  return (
    <Row className="Footer">
      <Container fluid className="m-0 px-0 pt-3">
        <Container className="d-flex flex-column flex-sm-row  p-4 pb-0">
          <Col className="d-flex flex-column align-items-start">
            <h5 className="text-uppercase mb-3">Follow Us On</h5>
            <Col as="p" className="d-flex">
              {
                socials.map((s: any, index: number) => (
                  <Col as="a" href={s.link} className="social d-inline-flex justify-content-start" key={`social-${index}`}>
                    <Image src={s.icon} alt={s.description} />
                  </Col>
                ))
              }
            </Col>
          </Col>
          <Col className="d-flex flex-column align-items-start align-items-sm-end pt-3 pt-sm-0">
            <h5 className="text-uppercase mb-3">Contact Us</h5>
            <a href="mailto:hello@hyper.mvm.gg">hello@hyper.mvm.gg</a>
          </Col>
        </Container>
        <Col className="d-flex justify-content-center gap-3 pb-5">
            <a
              href={LINKS.WEBPAGE.PRIVACY_POLICY}
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href={LINKS.WEBPAGE.TERMS_AND_CONDITIONS}
              target="_blank"
              rel="noreferrer"
            >
              Terms of service
            </a>
          </Col>
      </Container>
    </Row>
  )
}

export default Footer;