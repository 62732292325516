export enum ONBOARDING {
  SIGN_UP = "sign-up",
  SUCCESSFUL = "successful",
  SELECT_COUNTRY = "select-country",
  SELECT_REWARD = "select-reward",
  SUGGESTIONS = "suggestions",
  REFERRAL_CODE = "referral-code",
  USERNAME = "username",
  CONGRATULATIONS = "congratulations",
  START_DOWNLOAD = "start-download",
  DOWNLOAD_IN_PROGRESS = "download-in-progress",
  LOGGING_IN = "logging-in",
  LOG_IN = "log-in",
  WELCOME_BACK = "welcome-back",
  WELCOME = "welcome",
  FIND_FRIENDS = "find-friends",
  JOIN_COMMUNITY = "join-community",
  GET_APP = "get-app",
  INSTALL_TESTFLIGHT = "install-testflight",
  TURN_ON_NOTIFICATIONS = "turn-on-notifications",
  INSURANCE_BONUS = "insurance-bonus",
  TEST_FLIGHT = "testflight",
}

export enum TOPUP {
  PAYMENT_INITIATION = "/payments/initiate",
  PAYMENT_CONFIRMATION = "/payments/:provider/confirm",
  FIRST_TOPUP_SUCCESSFUL = "/successful-top-up/first",
  RETURN_TOPUP_SUCCESSFUL = "/successful-top-up/return",
  FAILED = "/payments/failed-top-up",
  PENDING = "/payments/pending-top-up",
}

export const ROOT = {
  ONBOARDING: "/onboarding",
  DOWNLOAD_NOW: "/download-now",
  APP: "/app",
  DASHBOARD: "/dashboard",
  HOME: "/",
} as const;

export const APP = {
  FRIENDS: "friends",
  GAMES: "games",
  DASHBOARD: "dashboard",
  REWARDS: "rewards",
  WALLET: "wallet",
  PLAY_GAME: "play-game",
} as const;

export const DASHBOARD = {
  FRIENDS: "friends",
  GAMES: "games",
  HOME: "home",
  REWARDS: "rewards",
  WALLET: "wallet",
} as const;
