import ImageAndroidLogo from "../images/android-icon.svg";
import ImagePalmStoreLogo from "../images/palmplay-icon.png";
import ImageSamsungStoreLogo from "../images/samsung-galaxy-store-logo.png";
import ImageAppleLogo from "../images/apple-logo-white.svg";
import ImageAmazonLogo from "../images/amazon-logo.svg";
import { LINKS } from "./constants";

export enum OperatingSystems {
  Android = 'android',
  Ios = 'ios',
}

export enum TranssionPhones {
  TECNO = "tecno",
  INFINIX = "infinix",
}

export enum PhoneVendors {
  SAMSUNG = "samsung",
  APPLE = "apple",
  AMAZON = "amazon",
  TRANSSION = "transsion",
}

export function getVendorDownloadLink(phoneVendor: PhoneVendors | null): string | null {
  let link;
  switch (phoneVendor) {
    case PhoneVendors.SAMSUNG:
      link = LINKS.WEBPAGE.GALAXY_STORE;
      break;
    case PhoneVendors.APPLE:
      link = null;
      break;
    case PhoneVendors.AMAZON:
      link = LINKS.WEBPAGE.AMAZON_STORE_HYPER_PAGE;
      break;
    case PhoneVendors.TRANSSION:
      link = LINKS.WEBPAGE.PALM_STORE_HYPER_PAGE;
      break;
    default:
      link = null;
  }

  return link;
}

export function getVendorLogo(phoneVendor: PhoneVendors | null) {
  let vendorLogo;
  switch (phoneVendor) {
    case PhoneVendors.SAMSUNG:
      vendorLogo = ImageSamsungStoreLogo;
      break;
    case PhoneVendors.APPLE:
      vendorLogo = ImageAppleLogo;
      break;
    case PhoneVendors.AMAZON:
      vendorLogo = ImageAmazonLogo;
      break;
    case PhoneVendors.TRANSSION:
      vendorLogo = ImagePalmStoreLogo;
      break;
    default:
      vendorLogo = ImageAndroidLogo;
  }

  return vendorLogo;
}
