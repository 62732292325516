import React from "react";
import { FileInfo } from "@nestcoinco/hyper-api-gateway-api-client";
import Button from "react-bootstrap/Button";
import GooglePlay from "../../images/google-play.svg";
import "./DownloadBtn.scss";

type IDownloadBtnProps = {
  className?: string,
  onClick?: () => void,
  file: FileInfo,
  disabled?: boolean,
}

function DownloadBtn ({ file, className, onClick, disabled }: IDownloadBtnProps) {
  return (
    <Button as="a" href={(file && file.url) || ""}
            disabled={disabled}
            onClick={onClick}
            className={`${className} DownloadBtn text-uppercase m-0 px-3 px-md-5 border-0 align-items-center`}>
      <span className="pr-4">Download App</span>
      <img src={GooglePlay} alt="Google Play" className="g-play"/>
    </Button>
  )
}

export default DownloadBtn;