import { LogLevel } from "../utils/constants";

const LOG_LEVEL_STRING = process.env.REACT_APP_LOG_LEVEL;
const LOG_LEVEL = LOG_LEVEL_STRING
  ? LogLevel[LOG_LEVEL_STRING.toLowerCase()] || NaN
  : NaN;

const logLevelConfig = { LOG_LEVEL };

export default logLevelConfig;
