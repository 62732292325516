import React from 'react';
import { useState } from 'react';
import './FloatingQr.scss';
import QrCode from '../QrCode/QrCode';
import { Options } from 'qr-code-styling';

function QrSnippetIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M13.3997 17.583H3.02417V21.1516H13.3997V17.583Z" fill="#262626"/>
      <path d="M21.2491 15.4915H15.4915V21.1517H21.2491V15.4915Z" fill="#262626"/>
      <path d="M11.6824 6.80878V11.6824H6.80878V6.80878H11.6824ZM13.1698 3H5.32135C4.0392 3 3 4.03919 3 5.32135V13.1698C3 14.452 4.0392 15.4912 5.32135 15.4912H15.4912V5.32135C15.4912 4.03919 14.452 3 13.1698 3Z" fill="#262626"/>
      <path d="M21.2486 3H17.5825V7.15357H21.2486V3Z" fill="#262626"/>
      <path d="M21.2486 9.24561H17.5825V13.3992H21.2486V9.24561Z" fill="#262626"/>
    </svg>
  );
}

export default function FloatingQr() {
  const [showQr, setShowQr] = useState<boolean>(true);
  const customQrOptions: Options = {
    width: 74.5,
    height: 74.5,
    image: '',
    backgroundOptions: {
      color: '',
    },
  }

  const toggleQrVisibility = () => {
    showQr ? setShowQr(false) : setShowQr(true);
  }

  return (
    <div className="d-none d-lg-flex flex-column align-items-center gap-3 position-fixed FloatingQr">
      <div
        className={`
          d-flex flex-column align-items-center gap-2 p-3 rounded-4 bg-white barcode-container ${showQr ? 'visible' : 'invisible'}
        `}
      >
        <p className="fw-bold mb-0 text-center heading">
          Download the App
        </p>
        <QrCode value="https://hypergame.onelink.me/4tib/dw8iofia" className="barcode" customOptions={customQrOptions} />
      </div>
      <button className="rounded-circle bg-white border-0 toggle-btn" onClick={toggleQrVisibility}>
        <QrSnippetIcon />
      </button>
    </div>
  )
}
