import React from "react";
import Image from "react-bootstrap/Image";
import { useNavigate } from "react-router-dom";

import BackButton from "../../../images/left-arrow-white.svg";
import "./OnboardingHeader.scss";

type IOnboardingHeaderProps = {
  amountActive: number;
  onClick?: () => void;
  steps?: number;
};

type IDashedComponentProps = {
  amountActive: number;
  steps: number;
};

function OnboardingHeaderDashedComponent({ value }: { value: boolean }) {
  const backgroundColor: string = value ? "#F4F0FF36" : "#6C53E8";
  return (
    <div
      className="OnboardingDashedComponent mx-1"
      style={{ background: backgroundColor }}
    />
  );
}

function ReturnAppropriateDashedProgress({
  amountActive,
  steps,
}: IDashedComponentProps) {
  return (
    <>
      {new Array(steps).fill("_").map((item, i) => (
        <OnboardingHeaderDashedComponent key={i} value={i >= amountActive} />
      ))}
    </>
  );
}

function OnboardingHeader({
  amountActive,
  onClick,
  steps = 4,
}: IOnboardingHeaderProps) {
  const navigate = useNavigate();

  const back = () => {
    navigate(-1);
  };

  return (
    <div className="OnboardingHeader">
      <button className="OnboardingHeaderBackButton ms-3 me-2" onClick={back}>
        <Image src={BackButton} alt="go back" />
      </button>
      <div
        className="OnboardingDashedComponentContainer"
        aria-label="Progress"
        role="progressbar"
        aria-valuemin={1}
        aria-valuemax={steps}
        aria-valuenow={amountActive}
      >
        <ReturnAppropriateDashedProgress
          amountActive={amountActive}
          steps={steps}
        />
      </div>
    </div>
  );
}

export default OnboardingHeader;
