import React, { useState, useEffect, memo } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

interface IToastOptions {
  delay?: number;
  variant: "error" | "success";
  toastMessage: string;
}

const DEFAULT_DELAY = 5000;

function CustomToast() {
  const [show, setShow] = useState(false);
  const [options, setOptions] = useState<IToastOptions>();

  const messageEventCallback = (event: MessageEvent<IToastOptions>) => {
    if (!event.data.toastMessage) return;
    setOptions({
      ...event.data,
      delay: event.data.delay ? event.data.delay : DEFAULT_DELAY,
    });
    setShow(true);
  };

  useEffect(() => {
    window.addEventListener("message", messageEventCallback);

    return () => {
      window.removeEventListener("message", messageEventCallback);
    };
  }, []);

  return (
    <ToastContainer
      position="top-center"
      className="p-3"
      style={{ zIndex: 99999 }}
    >
      <Toast
        onClose={() => setShow(false)}
        show={show}
        delay={options?.delay || DEFAULT_DELAY}
        autohide
        bg={options?.variant === "error" ? "danger" : "success"}
      >
        <Toast.Header>
          <strong className="me-auto">{options?.toastMessage || ""}</strong>
        </Toast.Header>
      </Toast>
    </ToastContainer>
  );
}

export function toast(options: IToastOptions) {
  window.parent.postMessage(options, "*");
}

export default memo(CustomToast);
