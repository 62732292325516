import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FiatCurrency,
  InitiatePaymentRequestBody,
  WalletsPaymentsClient
} from "@nestcoinco/hyper-api-gateway-api-client";
import apiConfig from "../../config/api-config";
import EventDispatcher from "../../utils/event-dispatcher";
import { EventTypes, ROUTES } from "../../utils/constants";
import PaymentStatus, { PaymentStatusType } from "../../components/Payments/PaymentStatus/PaymentStatus";
import { getPaymentTrials, setPaymentTrials } from "../../utils/storage";
import { AppIntentValues } from "../../utils/constants/events-types";

const MAX_PAYMENT_RETRY = 3

function PaymentInitiation() {
  const { search: locationSearch } = useLocation();
  const navigate = useNavigate();
  const [initiatePaymentState, setInitiatePaymentState] = useState<string>("0");
  const initiatePaymentStateMessage: Record<string, any> = {
    0: "Initiating payment. Please wait...",
    1: "Could not initiate payment. Please try again.",
    2: "Invalid payment URL. Please check the URL and try again.",
  };

  const generatePaymentLink = useCallback(async (requestBody: InitiatePaymentRequestBody) => {
    const paymentClient = new WalletsPaymentsClient(apiConfig);

    try {
      const pageResponse = (await paymentClient.initiatePayment(requestBody)).data;
      if (pageResponse.url) {
        EventDispatcher.dispatch(EventTypes.PAYMENTS.INITIATED, { message: `Redirecting to ${requestBody.provider}` });
        window.location.href = pageResponse.url;
      }
    } catch (e) {
      // Ignore since we already show the error message
      setInitiatePaymentState("1");
    }
  }, []);

  useEffect(() => {
    const paymentTrials = getPaymentTrials() || 0;
    if (paymentTrials >= MAX_PAYMENT_RETRY) {  
      EventDispatcher.dispatch(EventTypes.PAYMENTS.ERROR, { message: "Payment failed" });  
      navigate(ROUTES.TOPUP.FAILED);  
      return;  
    }  

    const queryParams = new URLSearchParams(locationSearch);  

    const provider = queryParams.get("provider");  
    const userId = queryParams.get("userId");  
    const planId = queryParams.get("planId");  
    const countryCode = queryParams.get("countryCode");  
    const currency = queryParams.get("currency") || "NGN";  
    const isSpecialOffer = queryParams.get("isSpecialOffer");  
    const isRetry = !!paymentTrials;  

    if (!provider || !userId || !planId || !countryCode || !currency) {  
      setInitiatePaymentState("2");  
      EventDispatcher.dispatch(EventTypes.PAYMENTS.ERROR, { message: "Invalid payment URL" });  
      return;  
    }  

    const requestBody: InitiatePaymentRequestBody = {  
      provider,  
      userId,  
      planId,  
      countryCode,  
      currency: currency as FiatCurrency,  
      isSpecialOffer: isSpecialOffer === "true",  
      isRetry
    };  

    generatePaymentLink(requestBody).catch();  
    setPaymentTrials(paymentTrials + 1);  
  }, [locationSearch, generatePaymentLink, navigate]);

  return (
    <PaymentStatus
      type={initiatePaymentState === "0" ? PaymentStatusType.LOADING : PaymentStatusType.DONE}
      title={initiatePaymentStateMessage[initiatePaymentState]}
      eventType={initiatePaymentState !== "0" ? EventTypes.PAYMENTS.ERROR : undefined}
      navigateUrl={AppIntentValues.PAYMENT_FAILED}
    />
  );
}

export default PaymentInitiation;