import React, { useEffect, useState, useRef } from "react";
import { Button, Image } from "react-bootstrap";
import { AvatarList } from "@nestcoinco/hyper-api-gateway-api-client";

import {
  handleUpdateUser,
  handleUploadAvatar,
} from "../../../api-clients/userAccountClient";
import { CustomSpinner } from "../../CustomSpinner/CustomSpinner";
import ImageBackIcon from "../../../images/left-arrow-white.svg";
import CameraIcon from "../../../images/camera.svg";
import CheckmarkIcon from "../../../images/checkmark_successful.svg";
import { handleFetchAvatars } from "../../../api-clients/usersAvatarsClient";
import { useUsernamePageContext } from "../../../context/username-page-context";
import "./AvatarModal.scss";
import { showSuccessToast } from "../../../utils/show-toast";

interface AvatarModalProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const colors = [
  "#E2DAFD",
  "#FFD499",
  "#F7D3BA",
  "#CDB9B0",
  "#D0F0FE",
  "#CBF4D2",
];

const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

const SUCCESS_MESSAGE = "Profile Photo Updated";

function AvatarModal({ isOpen, setIsOpen }: AvatarModalProps) {
  const profilePicRef = useRef<HTMLInputElement | null>(null);
  const [avatarFiles, setAvatarFiles] = useState<FileList | null>(null);
  const [avatars, setAvatars] = useState<AvatarList>();
  const [selectedAvatarId, setSelectedAvatarId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setProfile, profile } = useUsernamePageContext();

  useEffect(() => {
    if (!avatarFiles) return;

    const uploadAvatar = async () => {
      setIsLoading(true);
      const userProfile = await handleUploadAvatar(avatarFiles[0]);
      setIsLoading(false);
      setAvatarFiles(null);
      if (!userProfile) return;
      showSuccessToast(SUCCESS_MESSAGE);
      setProfile(userProfile);
      setIsOpen(false);
    };

    uploadAvatar();
  }, [avatarFiles, setIsOpen, setProfile]);

  useEffect(() => {
    (async () => {
      const userAvatars = await handleFetchAvatars();
      setAvatars(userAvatars);
    })();
  }, []);

  const handleChooseFile = () => {
    if (profilePicRef.current) {
      profilePicRef.current.click();
    }
  };

  useEffect(() => {
    if (!selectedAvatarId) return;

    async function updateAvatar() {
      setIsLoading(true);
      const user = await handleUpdateUser({ avatarId: selectedAvatarId });
      setIsLoading(false);
      if (!user) return;
      showSuccessToast(SUCCESS_MESSAGE);
      setProfile(user);
      setIsOpen(false);
    }

    updateAvatar();
  }, [selectedAvatarId, setIsOpen, setProfile]);

  return (
    <div className="avatar-selector-container MobileOnly" data-open={isOpen}>
      <div className="d-flex pt-5">
        <Button
          className="bg-transparent border-0"
          aria-label="go back"
          onClick={() => setIsOpen(false)}
        >
          <Image src={ImageBackIcon} alt="back" />
        </Button>
      </div>
      <h3 className="selector-heading ps-3 mt-2">Personalise your avatar</h3>
      {isLoading && (
        <div className="d-flex justify-content-center  py-2 ">
          <CustomSpinner className="text-white" />
        </div>
      )}
      <div className="d-flex flex-wrap justify-content-between avatar-images-container">
        <Button
          className="bg-transparent border-0 p-0"
          onClick={handleChooseFile}
        >
          <Image src={CameraIcon} />
        </Button>
        {avatars?.records?.map((avatar, i) => (
          <span
            className="avatar-btn-container"
            style={{ backgroundColor: getRandomColor() }}
            key={avatar.id}
          >
            <Button
              className="bg-transparent border-0"
              onClick={() => setSelectedAvatarId(avatar.id)}
              aria-label={`select avatar ${i + 1}`}
            >
              <Image src={avatar.url} className="avatar-img" alt="avatar" />
              {profile?.avatar?.id === avatar.id && (
                <Image src={CheckmarkIcon} className="avatar-checkmark" />
              )}
            </Button>
          </span>
        ))}
        <input
          type="file"
          ref={profilePicRef}
          className="d-none"
          onChange={(e) => setAvatarFiles(e.target.files)}
        />
      </div>
    </div>
  );
}

export default AvatarModal;
