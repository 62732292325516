import AtlanticSkyForceHeader from "../images/games/atlantic-sky-force-header.png";
import AtlanticSkyForceSignup from "../images/games/atlantic-sky-force-signup.png";
import AtlanticSkyForceHighlighted from "../images/games/atlantic-sky-force-highlighted.png";
import AtlanticSkyForceResult from "../images/games/atlantic-sky-force-result.png";
import BallsterHeader from "../images/games/ballster-header.png";
import BallsterSignup from "../images/games/ballster-signup.png";
import BallsterHighlighted from "../images/games/ballster-highlighted.png";
import BallsterResult from "../images/games/ballster-result.png";
import BirdVsBlockHeader from "../images/games/bird-vs-blocks-header.png";
import BirdVsBlockSignup from "../images/games/bird-vs-blocks-signup.png";
import BirdVsBlocksResult from "../images/games/bird-vs-blocks-result.png";
import BirdBumpHeader from "../images/games/bird-bump-header.png";
import BirdBumpSignup from "../images/games/bird-bump-signup.png";
import BirdBumpHighlighted from "../images/games/bird-bump-highlighted.png";
import BirdBumpResult from "../images/games/bird-bump-result.png";
import BreakEmBricksHeader from "../images/games/break-em-bricks-header.png";
import BreakEmBricksSignup from "../images/games/break-em-bricks-signup.png";
import BreakEmBricksHighlighted from "../images/games/break-em-bricks-highlighted.png";
import BreakEmBricksResult from "../images/games/break-em-bricks-result.png";
import CandyBlastHeader from "../images/games/candy-blast-header.png";
import CandyBlastSignup from "../images/games/candy-blast-signup.png";
import CandyBlastHighlighted from "../images/games/candy-blast-highlighted.png";
import CandyBlastResult from "../images/games/candy-blast-result.png";
import EscapeRunnerHeader from "../images/games/escape-runner-header.png";
import EscapeRunnerSignup from "../images/games/escape-runner-signup.png";
import EscapeRunnerHighlighted from "../images/games/escape-runner-highlighted.png";
import EscapeRunnerResult from "../images/games/escape-runner-result.png";
import HoopStarHeader from "../images/games/hoop-star-header.png";
import HoopStarSignup from "../images/games/hoop-star-signup.png";
import HoopStarHighlighted from "../images/games/hoop-star-highlighted.png";
import HoopStarResult from "../images/games/hoop-star-result.png";
import HunterKillerHeader from "../images/games/hunter-killer-header.png";
import HunterKillerSignup from "../images/games/hunter-killer-signup.png";
import HunterKillerHighlighted from "../images/games/hunter-killer-highlighted.png";
import HunterKillerResult from "../images/games/hunter-killer-result.png";
import KongClimbHeader from "../images/games/kong-climb-header.png";
import KongClimbSignup from "../images/games/kong-climb-signup.png";
import KongClimbHighlighted from "../images/games/kong-climb-highlighted.png";
import KongClimbResult from "../images/games/kong-climb-result.png";
import SamuraiRampageHeader from "../images/games/samurai-rampage-header.png";
import SamuraiRampageSignup from "../images/games/samurai-rampage-signup.png";
import SamuraiRampagHighlighted from "../images/games/samurai-rampage-highlighted.png";
import SamuraiRampageResult from "../images/games/samurai-rampage-result.png";
import SnakeVsNumbersHeader from "../images/games/snake-vs-numbers-header.png";
import SnakeVsNumbersSignup from "../images/games/snake-vs-numbers-signup.png";
import SnakeVsNumbersHighlighted from "../images/games/snake-vs-numbers-highlighted.png";
import SnakeVsNumbersResult from "../images/games/snake-vs-numbers-result.png";
import SporeHunterHeader from "../images/games/spore-hunter-header.png";
import SporeHunterSignup from "../images/games/spore-hunter-signup.png";
import SporeHunterHighlighted from "../images/games/spore-hunter-highlighted.png";
import SporeHunterResult from "../images/games/spore-hunter-result.png";
import ThreeChickensHeader from "../images/games/three-chickens-header.png";
import ThreeChickensSignup from "../images/games/three-chickens-signup.png";
import ThreeChickensHighlighted from "../images/games/three-chickens-highlighted.png";
import ThreeChickensResult from "../images/games/three-chickens-result.png";

export type IGameConfig = {
  name: string;
  headerBanner: string;
  signupBanner: string;
  chooseGameBanner: string;
  resultBanner: string;
  metaImage?: string;
};

const gameConfig: Record<string, IGameConfig> = {
  "atlantic-sky-force": {
    name: "Atlantic Sky Force",
    headerBanner: AtlanticSkyForceHeader,
    signupBanner: AtlanticSkyForceSignup,
    chooseGameBanner: AtlanticSkyForceHighlighted,
    resultBanner: AtlanticSkyForceResult,
  },
  ballster: {
    name: "Ballster",
    headerBanner: BallsterHeader,
    signupBanner: BallsterSignup,
    chooseGameBanner: BallsterHighlighted,
    resultBanner: BallsterResult,
  },
  "bird-bump": {
    name: "Bird Bump",
    headerBanner: BirdBumpHeader,
    signupBanner: BirdBumpSignup,
    chooseGameBanner: BirdBumpHighlighted,
    resultBanner: BirdBumpResult,
  },
  "bird-vs-blocks": {
    name: "Bird Vs Blocks",
    headerBanner: BirdVsBlockHeader,
    signupBanner: BirdVsBlockSignup,
    chooseGameBanner: BirdBumpHighlighted,
    resultBanner: BirdVsBlocksResult,
  },
  "break-em-bricks": {
    name: "Break 'Em Bricks",
    headerBanner: BreakEmBricksHeader,
    signupBanner: BreakEmBricksSignup,
    chooseGameBanner: BreakEmBricksHighlighted,
    resultBanner: BreakEmBricksResult,
  },
  "candy-blast": {
    name: "Candy Blast",
    headerBanner: CandyBlastHeader,
    signupBanner: CandyBlastSignup,
    chooseGameBanner: CandyBlastHighlighted,
    resultBanner: CandyBlastResult,
  },
  "escape-runner": {
    name: "Escape Runner",
    headerBanner: EscapeRunnerHeader,
    signupBanner: EscapeRunnerSignup,
    chooseGameBanner: EscapeRunnerHighlighted,
    resultBanner: EscapeRunnerResult,
  },
  "hoop-star": {
    name: "Hoop Star",
    headerBanner: HoopStarHeader,
    signupBanner: HoopStarSignup,
    chooseGameBanner: HoopStarHighlighted,
    resultBanner: HoopStarResult,
  },
  "hunter-killer": {
    name: "Hunter Killer",
    headerBanner: HunterKillerHeader,
    signupBanner: HunterKillerSignup,
    chooseGameBanner: HunterKillerHighlighted,
    resultBanner: HunterKillerResult,
  },
  "kong-climb": {
    name: "Kong",
    headerBanner: KongClimbHeader,
    signupBanner: KongClimbSignup,
    chooseGameBanner: KongClimbHighlighted,
    resultBanner: KongClimbResult,
  },
  "samurai-rampage": {
    name: "Samurai Rampage",
    headerBanner: SamuraiRampageHeader,
    signupBanner: SamuraiRampageSignup,
    chooseGameBanner: SamuraiRampagHighlighted,
    resultBanner: SamuraiRampageResult,
  },
  "snake-vs-numbers": {
    name: "Snake vs Numbers",
    headerBanner: SnakeVsNumbersHeader,
    signupBanner: SnakeVsNumbersSignup,
    chooseGameBanner: SnakeVsNumbersHighlighted,
    resultBanner: SnakeVsNumbersResult,
  },
  "spore-hunter": {
    name: "Spore Hunter",
    headerBanner: SporeHunterHeader,
    signupBanner: SporeHunterSignup,
    chooseGameBanner: SporeHunterHighlighted,
    resultBanner: SporeHunterResult,
  },
  "three-chickens": {
    name: "Three Chickens",
    headerBanner: ThreeChickensHeader,
    signupBanner: ThreeChickensSignup,
    chooseGameBanner: ThreeChickensHighlighted,
    resultBanner: ThreeChickensResult,
  },
};

export default gameConfig;
