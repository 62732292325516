import React, { ReactNode, useEffect, useRef } from "react";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";

import "./DefaultOnboardingStatusPage.scss";
import { renderHeaders } from "../../../utils/renderHeaders";
import { handleGetOnboardingState } from "../../../api-clients/userAccountClient";
import { OnboardingState } from "@nestcoinco/hyper-api-gateway-api-client";
import { ROUTES } from "../../../utils/constants";
import { getStoredUser } from "../../../utils/storage";
import FixedFooter from "../../../components/FixedFooter/FixedFooter";

type IDefaultOnboardingStatusPageProps = {
  titleText: ReactNode;
  secondaryText: string;
  primaryImage: string;
  secondaryIcon?: ReactNode;
  redirectDelay?: number;
  redirectPath?: string;
  metaData?: {
    title?: string;
    description?: string;
    image?: string;
  };
  stayOnPage?: boolean;
};

function DefaultOnboardingStatusPage({
  titleText,
  secondaryText,
  primaryImage,
  secondaryIcon,
  redirectPath,
  metaData,
  stayOnPage,
  redirectDelay = 2000,
}: IDefaultOnboardingStatusPageProps) {
  const navigate = useNavigate();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const accessToken = getStoredUser()?.tokens?.access;

  useEffect(() => {
    if (!accessToken || stayOnPage) return;
    const navigateAfterDelay = (path: string) => {
      const timeoutId = setTimeout(() => {
        navigate(path);
      }, redirectDelay);

      timeoutRef.current = timeoutId;
    };
    const basePath = ROUTES.ROOT.ONBOARDING;

    (async () => {
      if (redirectPath) {
        navigateAfterDelay(redirectPath);
        return;
      }

      const onboardingState = await handleGetOnboardingState();
      if (!onboardingState) {
        const path = `${basePath}/${ROUTES.ONBOARDING.SELECT_REWARD}`;
        navigateAfterDelay(path);
        return;
      }

      const returningUserpath = getRedirectPath(onboardingState);
      navigateAfterDelay(returningUserpath);

      return () => clearTimeout(timeoutRef.current);
    })();
  }, [accessToken, navigate, redirectDelay, redirectPath, stayOnPage]);

  return (
    <>
      {metaData &&
        renderHeaders({
          title: metaData?.title || "",
          description: metaData?.description || "",
          image: metaData?.image,
        })}
      <Container
        fluid
        className="MobileOnly position-relative defaultOnboardingStatusPage"
      >
        <Col className="d-flex justify-content-center primaryImageContainer">
          <Image src={primaryImage} className="primaryImage" />
        </Col>
        <Col className="d-flex flex-column ">
          <Col className="titleTextContainer d-flex justify-content-center">
            <h1 className="text-white mb-3">{titleText}</h1>
          </Col>
          <p className="secondary-text d-flex justify-content-center text-center px-5 text-white">
            {secondaryText}
          </p>

          <FixedFooter
            className="d-flex justify-content-center secondaryImageContainer"
            style={{ paddingBottom: 66 }}
          >
            {secondaryIcon}
          </FixedFooter>
        </Col>
      </Container>
    </>
  );
}

export default DefaultOnboardingStatusPage;

function getRedirectPath({
  claimedUsername,
  usedReferralCode,
}: OnboardingState) {
  const basePath = ROUTES.ROOT.ONBOARDING;
  let route;

  switch (true) {
    case claimedUsername:
      route = ROUTES.ONBOARDING.WELCOME_BACK;
      break;
    case usedReferralCode:
      route = ROUTES.ONBOARDING.USERNAME;
      break;
    default:
      route = ROUTES.ONBOARDING.REFERRAL_CODE;
  }
  return `${basePath}/${route}`;
}
