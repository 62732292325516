import {
  Country,
  ReferrerInfo,
  OAuthProvider,
  OAuthResponse,
  FileInfo,
  UsersSvcUser,
} from "@nestcoinco/hyper-api-gateway-api-client";

import Logger from "./logger";
import { AuthFlow, CHALLENGE_TIMER_RESETS_IN } from "./constants";

const SELECTED_COUNTRY_KEY = "hyper_selected_country";
const SELECTED_EARNING_KEY = "hyper_earning_goal";
const REFERRER_INFO_KEY = "hyper_referrer_info";
const USER_KEY = "hyper_user";
const AUTH_PROVIDER_KEY = "hyper_auth_provider";
const AUTH_CANCELLED_ERROR_KEY = "hyper_auth_cancelled";
const APK_KEY = "hyper_apk";
const PAYMENT_TRIALS = "payment_trials";
const HAS_VIEWED_DASHBOARD_EXPLAINER = "hyper_has_viewed_db_explainer";
const USER_PROFILE = "hyper_user_profile";
const PWA_INSTALL_STATUS = "hyper_pwa_install_status";
const PWA_INSTALL_PROMPT_TIME = "hyper_pwa_install_prompt_time";
const PWA_INSTALL_ORIGIN = "hyper_pwa_install_origin";
const HAS_VIEWED_FIRST_GAME_EXPLAINER = "hyper_has_viewed_first_game_explainer";
const AUTH_FLOW = "hyper_auth_flow";
const CHALLENGE_TIMERS = "hyper_challenge_timers";

function setStoredData(key: string, data: any, isSessionStorage = false) {
  try {
    const storage = isSessionStorage ? sessionStorage : localStorage;
    storage.setItem(key, JSON.stringify(data));
  } catch (error) {
    Logger.error(error);
  }
}

function getStoredData<T>(key: string, isSessionStorage = false): T | null {
  try {
    const storage = isSessionStorage ? sessionStorage : localStorage;
    const storedData = storage.getItem(key);
    return storedData ? JSON.parse(storedData) : null;
  } catch (error) {
    Logger.error(error);
    return null;
  }
}

function deleteStoredData(key: string, isSessionStorage = false) {
  try {
    const storage = isSessionStorage ? sessionStorage : localStorage;
    const storedData = storage.getItem(key);
    if (!storedData) return;
    storage.removeItem(key);
  } catch (error) {
    Logger.error(error);
  }
}

export function setStoredSelectedCountry(country: Country) {
  sessionStorage.setItem(SELECTED_COUNTRY_KEY, JSON.stringify(country));
}

export function getStoredSelectedCountry(): Country | null {
  const storedCountry = sessionStorage.getItem(SELECTED_COUNTRY_KEY);
  return storedCountry ? JSON.parse(storedCountry) : null;
}

export function setStoredSelectedEarnings(hyperAmount: number) {
  sessionStorage.setItem(SELECTED_EARNING_KEY, JSON.stringify(hyperAmount));
}

export function getStoredSelectedEarning(): number | null {
  const storedEarning = sessionStorage.getItem(SELECTED_EARNING_KEY);
  return storedEarning ? JSON.parse(storedEarning) : null;
}

export function setStoredReferrerInfo(referrerInfo: ReferrerInfo) {
  localStorage.setItem(REFERRER_INFO_KEY, JSON.stringify(referrerInfo));
}

export function getStoredReferrerInfo(): ReferrerInfo | null {
  const storedReferrerInfo = localStorage.getItem(REFERRER_INFO_KEY);
  return storedReferrerInfo ? JSON.parse(storedReferrerInfo) : null;
}

export function clearStoredReferrerInfo() {
  localStorage.removeItem(REFERRER_INFO_KEY);
}

export function setStoredUser(user: OAuthResponse) {
  sessionStorage.setItem(USER_KEY, JSON.stringify(user));
}

export function getStoredUser(): OAuthResponse | null {
  const storedUser = sessionStorage.getItem(USER_KEY);
  return storedUser ? JSON.parse(storedUser) : null;
}

export function setStoredAuthProvider(provider: OAuthProvider) {
  localStorage.setItem(AUTH_PROVIDER_KEY, JSON.stringify(provider));
}

export function getStoredAuthProvider(): OAuthProvider | null {
  const storedAuthProvider = localStorage.getItem(AUTH_PROVIDER_KEY);
  return storedAuthProvider ? JSON.parse(storedAuthProvider) : null;
}

interface AuthCancelledError {
  error: string;
  provider: string;
}
export function setStoredAuthCancelledError(error: AuthCancelledError) {
  sessionStorage.setItem(AUTH_CANCELLED_ERROR_KEY, JSON.stringify(error));
}

export function getStoredAuthCancelledError(): AuthCancelledError | null {
  const error = sessionStorage.getItem(AUTH_CANCELLED_ERROR_KEY);
  return error ? JSON.parse(error) : null;
}

export function setStoredApk(apk: FileInfo) {
  sessionStorage.setItem(APK_KEY, JSON.stringify(apk));
}

export function getStoredApk(): FileInfo | null {
  const storedApk = sessionStorage.getItem(APK_KEY);
  return storedApk ? JSON.parse(storedApk) : null;
}

export function clearStoredAuthCancelledError() {
  sessionStorage.removeItem(AUTH_CANCELLED_ERROR_KEY);
}

export function setPaymentTrials(trials: number) {
  sessionStorage.setItem(PAYMENT_TRIALS, trials.toString());
}

export function getPaymentTrials(): number | null {
  const paymentTrials = sessionStorage.getItem(PAYMENT_TRIALS);
  return paymentTrials ? Number(paymentTrials) : null;
}

export function clearPaymentTrials() {
  return sessionStorage.removeItem(PAYMENT_TRIALS);
}

export function setStoredHasViewedDashboardExplainer(hasViewed: boolean) {
  setStoredData(HAS_VIEWED_DASHBOARD_EXPLAINER, hasViewed);
}

export function getStoredHasViewedDashboardExplainer(): boolean | null {
  return getStoredData<boolean>(HAS_VIEWED_DASHBOARD_EXPLAINER);
}

export function setStoredUserProfile(profile: UsersSvcUser) {
  setStoredData(USER_PROFILE, profile);
}

export function getStoredUserProfile(): UsersSvcUser | null {
  return getStoredData<UsersSvcUser>(USER_PROFILE);
}

interface PwaInstallStatusData {
  openedPwa: boolean;
  installationDate: number;
}

export function setStoredPwaInstallStatus(
  pwaInstallStatusData: PwaInstallStatusData
) {
  setStoredData(PWA_INSTALL_STATUS, pwaInstallStatusData);
}

export function getStoredPwaInstallStatus(): PwaInstallStatusData | null {
  return getStoredData<PwaInstallStatusData>(PWA_INSTALL_STATUS);
}

export function deleteStoredPwaInstallStatus() {
  deleteStoredData(PWA_INSTALL_STATUS);
}

export function setStoredPwaInstallPromptTime(timeInMs: number) {
  setStoredData(PWA_INSTALL_PROMPT_TIME, timeInMs);
}

export function getStoredPwaInstallPromptTime(): number | null {
  return getStoredData<number>(PWA_INSTALL_PROMPT_TIME);
}

export function setStoredPwaInstallOrigin(origin: string) {
  setStoredData(PWA_INSTALL_ORIGIN, origin);
}

export function getStoredPwaInstallOrigin(): string | null {
  return getStoredData<string>(PWA_INSTALL_ORIGIN);
}

export function setStoredHasViewedFirstGameExplainer(hasViewed: boolean) {
  setStoredData(HAS_VIEWED_FIRST_GAME_EXPLAINER, hasViewed);
}

export function getStoredHasViewedFirstGameExplainer(): boolean | null {
  return getStoredData<boolean>(HAS_VIEWED_FIRST_GAME_EXPLAINER);
}

export function setStoredAuthFlow(flow: AuthFlow) {
  setStoredData(AUTH_FLOW, flow, true);
}

export function getStoredAuthFlow(): AuthFlow | null {
  return getStoredData<AuthFlow>(AUTH_FLOW, true);
}

export function getStoredChallengeTimers(): Record<string, number> | null {
  return getStoredData<Record<string, number>>(CHALLENGE_TIMERS);
}

export function setStoredChallengeTimer(challengeId: string) {
  const currentStoredTimers = getStoredChallengeTimers();
  let timers: Record<string, number>;
  const expirationTime = Date.now() + CHALLENGE_TIMER_RESETS_IN;
  if (!currentStoredTimers) {
    timers = {
      [challengeId]: expirationTime,
    };
    setStoredData(CHALLENGE_TIMERS, timers);
    return;
  }
  timers = {
    ...currentStoredTimers,
    [challengeId]: expirationTime,
  };
  setStoredData(CHALLENGE_TIMERS, timers);
}

export function clearSessionStorage() {
  sessionStorage.clear();
}
