import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import "./Collapsible.scss"

type ICollapsibleItem = { title: string, body: string };

type ICollapsibleData = {
  data: Array<ICollapsibleItem>
}

function Collapsible({ data }: ICollapsibleData) {
  return (
    <Accordion defaultActiveKey="0" className="Collapsible border-0 text-start" flush>
      {
        data.map((d: ICollapsibleItem, index: number) => (
          <Accordion.Item eventKey={index + ""} className="border-0" key={`collapsible-item-${index}`}>
            <Accordion.Header>{d.title}</Accordion.Header>
            <Accordion.Body dangerouslySetInnerHTML={{__html: d.body}}/>
          </Accordion.Item>
        ))
      }
    </Accordion>
  );
}

export default Collapsible;