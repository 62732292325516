import React, { useEffect, useState } from "react";
import { Button, Col, Stack } from "react-bootstrap";
import "./PaymentStatus.scss";
import { AppIntentValues, PaymentEventTypes } from "../../../utils/constants/events-types";
import EventDispatcher from "../../../utils/event-dispatcher";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

export enum PaymentStatusType {
  LOADING = "loading",
  DONE = "done"
}

interface PaymentStatusProps {
  type: PaymentStatusType;
  title: string;
  message?: string;
  eventType?: PaymentEventTypes;
  navigateUrl?: string;
}

function PaymentStatus({ type, title, message, eventType, navigateUrl = AppIntentValues.HOME }: PaymentStatusProps) {
  const [showContinueBtn, setShowContinueBtn] = useState<boolean>(false);

  const handleContinue = () => {
    if (eventType) {
      EventDispatcher.dispatch(eventType, { message: message || title });
      window.location.href = navigateUrl;
    }
    return;
  }

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (!showContinueBtn) {
      timeoutId = setTimeout(() => setShowContinueBtn(true), 4000);
    }

    return () => {
      clearTimeout(timeoutId);
    }
  }, [showContinueBtn]);

  return (
    <Col className="d-flex flex-column text-center justify-content-center px-3 align-items-center PaymentStatus">
      <Stack
        gap={type === PaymentStatusType.LOADING ? 4 : 5} 
        className={`
          text-center
          ${type === PaymentStatusType.LOADING || !showContinueBtn ? 'justify-content-center' : 'justify-content-end'}
        `}
      >
        {type === PaymentStatusType.LOADING && <LoadingSpinner />}
        <div>
          <p className="text-center PaymentStatusTitle">
            {title}
          </p>
          {message && 
            <p className="mt-3 text-center PaymentStatusMessage">
              {message}
            </p>
          }
        </div>
        {type === PaymentStatusType.DONE && <p className="RedirectMessage">You will be redirected shortly</p>}
      </Stack>
      
      {type === PaymentStatusType.DONE && showContinueBtn && (
        <div>
          <Button variant="link" onClick={handleContinue}>
            Continue
          </Button>
        </div>
      )}
    </Col>
  )
}

export default PaymentStatus;