import React, { useCallback, useEffect } from "react";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  WalletsPaymentsClient
} from "@nestcoinco/hyper-api-gateway-api-client";
import apiConfig from '../../config/api-config';
import { ROUTES } from "../../utils/constants";
import { usePaymentConfirmationContext } from "../../components/Payments/PaymentConfirmationProvider";
import PaymentStatus, { PaymentStatusType } from "../../components/Payments/PaymentStatus/PaymentStatus";

function PaymentConfirmation() {
  const { provider } = useParams();
  const navigate = useNavigate();
  const { search: locationSearch } = useLocation();
  const { updateTopupAmount } = usePaymentConfirmationContext();

  const postPaymentInfo = useCallback(async (provider: string, reference: string) => {
    const paymentClient = new WalletsPaymentsClient(apiConfig);
    try {
      const pageResponse = (await paymentClient.confirmPayment(provider, { reference })).data;

      if (pageResponse.success) {
        updateTopupAmount(pageResponse.amount);
        if (pageResponse.isFirstTime) {
          navigate(ROUTES.TOPUP.FIRST_TOPUP_SUCCESSFUL);
        } else {
          navigate(ROUTES.TOPUP.RETURN_TOPUP_SUCCESSFUL);
        }
      } else {
        navigate(ROUTES.TOPUP.FAILED);
      }
    } catch (e) {
      navigate(ROUTES.TOPUP.PENDING);
    }
  }, [navigate, updateTopupAmount]);

  useEffect(() => {
    const getReferenceFromParams = () => {
      const queryParams = new URLSearchParams(locationSearch);
      const reference = queryParams.get('reference') || '';
      return reference;
    }

    const reference = getReferenceFromParams();
    postPaymentInfo(provider as string, reference).catch();
  }, [provider, locationSearch, postPaymentInfo]);

  return (
    <PaymentStatus
      type={PaymentStatusType.LOADING}
      title="Confirming Payment"
      message="Your payment is being confirmed..."
    />
  );
}

export default PaymentConfirmation;