import React, { type ReactNode } from "react";
import { Button, type ButtonProps } from "react-bootstrap";

import "./PrimaryActionButton.scss";

type IPrimaryActionButtonProps = ButtonProps & {
  textColor?: string;
  text: ReactNode;
  leftImage?: React.ReactNode;
  rightImage?: React.ReactNode;
  textClassName?: string;
  buttonClassName?: string;
  imgClassName?: string;
  isExternal?: boolean;
};

function PrimaryActionButton({
  textColor,
  text,
  onClick,
  disabled,
  isExternal,
  leftImage,
  rightImage,
  imgClassName,
  textClassName,
  buttonClassName = '',
  ...restProps
}: IPrimaryActionButtonProps) {
  const TextStyle = {
    color: textColor,
  };

  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      target={isExternal ? '_blank' : undefined}
      rel={isExternal ? 'noopener noreferrer' : undefined}
      className={`PrimaryButton ${buttonClassName}`}
      {...restProps}
    >
      <span
        className={`h-100 buttonTextClassName ${textClassName ? textClassName : ''}`}
        style={TextStyle}
      >
        {" "}
        {leftImage && (
          typeof leftImage === 'string' ? (
            <img
              className={` ${imgClassName}`}
              src={leftImage}
              alt="button_image"
            />
          ) : leftImage
        )}{" "}
        {text}{" "}
        {rightImage && (
          typeof rightImage === 'string' ? (
            <img
              className={` ${imgClassName}`}
              src={rightImage}
              alt="button_image"
            />
          ) : rightImage
        )}{" "}
      </span>
    </Button>
  );
}

export default PrimaryActionButton;
