import React, { useEffect, useState, useLayoutEffect } from "react";
import { UAParser } from "ua-parser-js";

import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import LandingPage from "../LandingPage/LandingPage";
import { getCurrentUrl, registerTrackingEvent } from "../../utils/helpers";
import { PAGE_NAMES, TRACKING_EVENTS } from "../../utils/constants";

function checkIsMobile(screenWidth: number) {
  const MOBILE_SCREEN_THRESHOLD = 830;
  const parser = new UAParser();
  const uaResult = parser.getResult();

  return !!(
    screenWidth <= MOBILE_SCREEN_THRESHOLD ||
    uaResult.device.type === "mobile" ||
    uaResult.device.type === "tablet" ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/Android/i)
  );
}

function Homepage() {
  const { width: screenWidth } = useWindowDimensions();
  const [, setIsMobile] = useState(checkIsMobile(screenWidth));

  useEffect(() => {
    registerTrackingEvent({
      name: TRACKING_EVENTS.LANDING_PAGE_VISIT,
      payload: { page_name: PAGE_NAMES.home, url: getCurrentUrl() },
    });
  }, []);

  useLayoutEffect(() => {
    setIsMobile(checkIsMobile(screenWidth));
  }, [screenWidth]);

  return <LandingPage />;
}

export default Homepage;
