import React from "react";
import { Container, Col } from "react-bootstrap";

import "./HyperTextField.scss";

type IHyperTextFieldProps = {
  hint: string;
  errorMessage?: string;
  onChange: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  value?: string;
};

function HyperTextField({ hint, onChange, value = "" }: IHyperTextFieldProps) {
  const characterCount = value.length;

  return (
    <Container fluid className="d-flex flex-column px-0 field-container">
      <Col className="hyper-text-field">
        <Col className="mx-0 hyper-text-field-additional-info-row">
          {hint && <p className="hyper-text-field-hint">{hint}</p>}
          <p className="hyper-text-field-character-count">{characterCount}</p>
        </Col>
        <textarea
          onChange={onChange}
          className="hyper-text-field-text-area"
          rows={1}
          placeholder=""
          value={value}
          cols={50}
        />
      </Col>
    </Container>
  );
}

export default HyperTextField;
