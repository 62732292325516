import axios, { AxiosError } from "axios";

import { clearSessionStorage, getStoredUser } from "../utils/storage";
import { AxiosHeaders } from "axios";
import { showErrorToast } from "../utils/show-toast";

export const axiosInstance = axios.create({
  // app build is used for api specs to allow access to certain endpoints
  headers: { "x-app-build": "220" },
});

axiosInstance.interceptors.request.use((config) => {
  const user = getStoredUser();
  if (!config.headers) {
    config.headers = {} as AxiosHeaders;
  }
  config.headers.Authorization = user
    ? `Bearer ${user.tokens?.access || ""}`
    : "";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // handle api errors when using this axios instance
    if (!error.response) {
      const toastMessage = "Problem with internet connection";
      showErrorToast(toastMessage);
      return;
    }
    if (error instanceof AxiosError) {
      const toastMessage = error.response.data?.message || "Server Error";
      showErrorToast(toastMessage);
    }
    if (error.response.status === 401) {
      clearSessionStorage();
    }

    throw error;
  }
);
