import React from "react";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./DownloadStep.scss";

type IDownloadStepProps = {
  image: any;
  step: number
  content: string;
  title?: string;
}

function DownloadStep ({ image, title, step, content }: IDownloadStepProps) {
  return (
    <Col className={`DownloadStep step-${step % 2} d-flex`}>
      <Col md={6} className="px-3 px-lg-4 py-md-5 body d-flex flex-column justify-content-center">
        <h3>{title || `Step ${step}`}</h3>
        <p>{content}</p>
      </Col>
      <Col md={6} className="px-3 px-lg-4 pb-5 pt-md-5 image d-flex align-items-center justify-content-center">
        <Image src={image} alt={title || `Step ${step}`}/>
      </Col>
    </Col>
  )
}

export default DownloadStep;