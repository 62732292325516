export const PAGE_NAMES = {
  home: "Homepage",
  startDownload: "Start Download Page",
  appDownloading: "Hyper App is Downloading Page",
  signup: "Signup Page",
  signupSuccessful: "Sign up successful Page",
  loginSuccessful: "Log In Successful Page",
  selectCountry: "Select country Page",
  selectReward: "Select reward Page",
  suggestions: "Suggestions Page",
  referralCode: "Referral code Page",
  createUsername: "Create username Page",
  congratulatoryReward: "Congratulatory Reward Page",
  welcomeBack: "Welcome Back",
};