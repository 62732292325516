import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import PaymentStatus, { PaymentStatusType } from '../../components/Payments/PaymentStatus/PaymentStatus';
import EventDispatcher from '../../utils/event-dispatcher';
import { EventTypes } from '../../utils/constants';
import { AppIntentValues } from '../../utils/constants/events-types';

function TopupSuccessful() {
  useEffect(() => {
    EventDispatcher.dispatch(EventTypes.PAYMENTS.SUCCESSFUL, { message: "Payment successful" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Topup Successful 💰💃🏾🤩 | Hyper by Metaverse Magna</title>
        <meta name="og:title" content="Topup Successful 💰💃🏾🤩 | Hyper by Metaverse Magna"/>
      </Helmet>

      <PaymentStatus
        type={PaymentStatusType.DONE}
        title="Payment Confirmed"
        message="Your payment is successful"
        eventType={EventTypes.PAYMENTS.SUCCESSFUL}
        navigateUrl={AppIntentValues.PAYMENT_SUCCESSFUL}
      />
    </>
  )
}

export default TopupSuccessful;