import React, { CSSProperties } from "react";
import "./LoadingDots.scss";

const COUNT = 3;

interface LoadingDotsProps {
  containerStyles?: CSSProperties;
  dotStyles?: CSSProperties;
}
function LoadingDots({ containerStyles, dotStyles }: LoadingDotsProps) {
  return (
    <span className="loading-dots" style={containerStyles}>
      {Array.from({ length: COUNT }).map((_, i) => (
        <span key={i} className="dot" style={dotStyles} />
      ))}
    </span>
  );
}

export default LoadingDots;
