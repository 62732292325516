import React, { useEffect, useState } from "react";
import { FileInfo } from "@nestcoinco/hyper-api-gateway-api-client";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import DownloadBtn from "../DownloadBtn/DownloadBtn";
import HyperLogo from "../../images/hyper.svg";
import "./StickyHeader.scss";
import { Link } from "react-router-dom";

type IStickyHeaderProps = {
  apk: FileInfo,
  onDownloadClicked?: () => void,
  disableDownload?: boolean
};

function StickyHeader ({ apk, onDownloadClicked, disableDownload }: IStickyHeaderProps) {
  const [sticky, setSticky] = useState<string>("");

  useEffect(() => {
    window.addEventListener("scroll", checkAndSetSticky);
    return () => {
      window.removeEventListener("scroll", checkAndSetSticky);
    };
  }, []);

  const checkAndSetSticky = () => {
    const scrollTop = window.scrollY;
    setSticky(scrollTop >= 500 ? "sticky" : "");
  };

  return (
    <Row className={`StickyHeader w-100 d-flex ${sticky}`}>
      <Container fluid className="m-0 px-lg-5 py-3 d-flex align-items-center w-100">
        <Col className="px-2 px-md-3 px-lg-4 d-flex flex-grow-1 justify-content-between align-items-center">
          <Link to="/"><Image src={HyperLogo} className="h-logo" alt="Hyper Logo"/></Link>
          <DownloadBtn file={apk} className="sm" onClick={onDownloadClicked} disabled={disableDownload} />
        </Col>
      </Container>
    </Row>
  )
}

export default StickyHeader;