/* eslint-disable no-console */
import config from "../config/log-level";
import { LogLevel } from "./constants";

// Use `any` as the message can be of any type from string, number, array, object, boolean e.t.c
type MessageType = any;

export default class Logger {
  /**
   * Critical error messages
   *
   * @param {MessageType} message
   */
  static error(message: MessageType): void {
    if (config.LOG_LEVEL <= LogLevel.error) {
      console.error(message);
    }
  }

  /**
   * Warning messages that are not critical
   *
   * @param {MessageType} message
   */
  static warn(message: MessageType): void {
    if (config.LOG_LEVEL <= LogLevel.warn) {
      console.warn(message);
    }
  }

  /**
   * An alias for `info` method
   *
   * @param {MessageType} message
   */
  static log(message: MessageType): void {
    this.info(message);
  }

  /**
   * General information message
   *
   * @param {MessageType} message
   */
  static info(message: MessageType): void {
    if (config.LOG_LEVEL <= LogLevel.info) {
      console.info(message);
    }
  }

  /**
   * Debug messages that are useful for developers
   *
   * @param {MessageType} message
   */
  static debug(message: MessageType): void {
    if (config.LOG_LEVEL >= LogLevel.debug) {
      // `console.debug` requires enabling debug mode in the browser. Using `console.log` instead
      console.log(message);
    }
  }

  static trace(message: MessageType): void {
    if (config.LOG_LEVEL >= LogLevel.trace) {
      console.trace(message);
    }
  }
}
