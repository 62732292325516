import Tiktok from "../images/tiktok.svg";
import X from "../images/x.svg";
import Instagram from "../images/instagram.svg";
import WhatsApp from "../images/whatsapp-black.svg";
import Discord from "../images/discord.svg";

const socials = [
  {
    icon: Instagram,
    description: "Follow us on Instagram",
    link: "https://www.instagram.com/metaversemagna/",
  },
  {
    icon: X,
    description: "Follow us on X (Twitter)",
    link: "https://twitter.com/metaversemagna",
  },
  {
    icon: WhatsApp,
    description: "Join our WhatsApp Community",
    link: "https://chat.whatsapp.com/F0vmrfHNfDxGUGYh087VHM",
  },
  {
    icon: Discord,
    description: "Follow us on Discord",
    link: "https://discord.com/invite/YHd3a8jes8",
  },
  {
    icon: Tiktok,
    description: "Follow us on Tiktok",
    link: "https://www.tiktok.com/@metaversemagna?_t=8cXDtSY4CKz&_r=1",
  }
];

export default socials;