import React from "react";
import "./LoadingSpinner.scss";

export default function LoadingSpinner() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none" className="Spinner">
        <path d="M60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30ZM8.99745 30C8.99745 41.5994 18.4006 51.0026 30 51.0026C41.5994 51.0026 51.0026 41.5994 51.0026 30C51.0026 18.4006 41.5994 8.99745 30 8.99745C18.4006 8.99745 8.99745 18.4006 8.99745 30Z" fill="#D9D9D9"/>
        <path d="M55.5013 30C57.9859 30 60.0345 32.0252 59.6633 34.4819C59.301 36.8796 58.6486 39.23 57.7164 41.4805C56.2087 45.1203 53.999 48.4274 51.2132 51.2132C48.4274 53.999 45.1203 56.2087 41.4805 57.7164C39.23 58.6486 36.8796 59.301 34.4819 59.6633C32.0252 60.0345 30 57.9859 30 55.5013C30 53.0167 32.0366 51.0507 34.4644 50.5226C35.6834 50.2574 36.8793 49.8835 38.0373 49.4038C40.5855 48.3484 42.9008 46.8013 44.851 44.851C46.8013 42.9008 48.3484 40.5855 49.4038 38.0373C49.8835 36.8793 50.2574 35.6834 50.5226 34.4644C51.0507 32.0366 53.0167 30 55.5013 30Z" fill="#6C53E8"/>
      </svg>
    </div>
  )
}
