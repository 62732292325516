export enum IpInfoCountry {
  NG = "NG",
}

export interface IpInfo {
  ip: string;
  city: string;
  region: string;
  country: IpInfoCountry;
  loc: string;
  org: string;
  timezone: string;
}
