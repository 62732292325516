import { useState, useEffect } from "react";
import { FileInfo } from "@nestcoinco/hyper-api-gateway-api-client";

import { fetchAPKs } from "../api-clients/filesClient";
import defaultAPK from "../config/default-apk";
import { getStoredApk, setStoredApk } from "../utils/storage";

function useDownloadApp() {
  const [apks, setApks] = useState<Array<FileInfo>>([defaultAPK]);

  useEffect(() => {
    const getAPKs = async () => {
      const apks = await fetchAPKs();
      if (!apks) return;
      setStoredApk(apks[0]);
      setApks(apks);
    };
    const storedApk = getStoredApk();
    if (storedApk) {
      setApks([storedApk]);
      return;
    }

    getAPKs();
  }, []);

  return { apk: apks[0] };
}

export { useDownloadApp };
