import { useState, useEffect } from "react";

import {
  getStoredChallengeTimers,
  setStoredChallengeTimer,
} from "../utils/storage";
import { CHALLENGE_TIMER_RESETS_IN } from "../utils/constants";

function useChallengeTimer(challengeId: string) {
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const expirationTime = getOrSetChallengeExpiration(challengeId);

    const updateCountdown = () => {
      const now = Date.now();
      const timeDifference = expirationTime - now;

      if (timeDifference <= 0) {
        setTimeLeft("00h : 00m : 00s");
        return;
      }

      const hours = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      // To ensure each part has two digits to minimise layout shift
      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      setTimeLeft(
        `${formattedHours}h : ${formattedMinutes}m : ${formattedSeconds}s`
      );
    };

    updateCountdown();
    const intervalId = setInterval(updateCountdown, 1000);

    return () => clearInterval(intervalId);
  }, [challengeId]);

  return timeLeft;
}

export default useChallengeTimer;

function getOrSetChallengeExpiration(challengeId: string): number {
  const currentStoredTimers = getStoredChallengeTimers();
  const now = Date.now();
  const expirationTime = now + CHALLENGE_TIMER_RESETS_IN;

  if (!currentStoredTimers || !currentStoredTimers[challengeId]) {
    setStoredChallengeTimer(challengeId);
    return expirationTime;
  }

  const currentExpiration = currentStoredTimers[challengeId];
  if (currentExpiration < now) {
    setStoredChallengeTimer(challengeId);
    return expirationTime;
  }
  return currentExpiration;
}
