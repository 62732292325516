import Step1 from "../images/step1.png";
import Step2 from "../images/step2.png";
import Step3 from "../images/step3.png";
import Step4 from "../images/step4.png";
import Step5 from "../images/step5.png";

const steps = [
  {
    image: Step1,
    title: "Step 1: Tap download or scan QR code",
    content: "This will download a file to your phone. To complete the installation process, open the downloaded file. This process should take less than 5 minutes."
  },
  {
    image: Step2,
    title: `Step 2: Tap "download anyway" to download the file.`,
    content: `This is a very safe and secure way of installing the app. The "file might be harmful" shows for every app that is downloaded outside of the playstore.`
  },
  {
    image: Step3,
    title: "Step 3: Open the downloaded file to install it.",
    content: `Open the downloaded file from your notifications, or browser’s download section, or go to files in your phone and search "Hyper"`
  },
  {
    image: Step4,
    title: "Step 4: Tap on install & Approve Installation from unknown sources.",
    content: "If the installation does not start immediately, click on settings so that you get redirected to “Install unknown apps”. Turn on “Allow from this source”."
  },
  {
    image: Step5,
    title: "Step 5: App installed.",
    content: "Tap open after app is installed"
  }
];

export default steps;