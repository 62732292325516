import { TournamentsExternalsClient } from "@nestcoinco/hyper-api-gateway-api-client";

import apiConfig from "../config/api-config";
import { axiosInstance } from "../config/axios-instance";

const tournamentsExternalsClient = new TournamentsExternalsClient(
  apiConfig,
  undefined,
  axiosInstance
);

const handleGetLatestWinners = async () => {
  try {
    const latestWinners = (await tournamentsExternalsClient.getLatestWinners())
      .data;
    return latestWinners;
  } catch {
    return;
  }
};

export { handleGetLatestWinners };
