import React, { useState, type ReactNode, ReactElement } from "react";
import { Navigate } from "react-router-dom";

import { getStoredUser } from "../../utils/storage";
import { ROUTES } from "../../utils/constants";

interface ProtectedRouteProps {
  children: ReactNode;
  redirectPath?: string;
}
const defaultRedirectPath = `/onboarding/${ROUTES.ONBOARDING.SIGN_UP}`;

function ProtectedRoute({
  children,
  redirectPath = defaultRedirectPath,
}: ProtectedRouteProps): ReactElement {
  const [user] = useState(getStoredUser());

  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }

  return <>{children}</>;
}

export { ProtectedRoute };
