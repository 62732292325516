import { useEffect, useState } from "react";
import UAParser from "ua-parser-js";
import { PhoneVendors, TranssionPhones } from "../utils/vendor";

function useVendorDetection() {
  const [phoneVendor, setPhoneVendor] = useState<PhoneVendors | null>(null);

  useEffect(() => {
    const parser = new UAParser();
    const vendor = parser.getDevice().vendor?.toLowerCase();
    const userAgent = parser.getResult().ua;

    const isAmazonDevice = /Kindle|Silk/.test(userAgent);
    const isSamsungDevice =
      /SAMSUNG|SGH-[INT]|GT-[IPN]|SM-[NPTZG]|SHV-E|SCH-[IJRS]|SPH-L/i.test(
        userAgent
      );
    const isAppleDevice = vendor?.includes(PhoneVendors.APPLE);
    const isTecnoDevice = vendor?.includes(TranssionPhones.TECNO);
    const isInfinixDevice = vendor?.includes(TranssionPhones.INFINIX);

    if (isAmazonDevice) setPhoneVendor(PhoneVendors.AMAZON);
    else if (isAppleDevice) setPhoneVendor(PhoneVendors.APPLE);
    else if (isTecnoDevice || isInfinixDevice)
      setPhoneVendor(PhoneVendors.TRANSSION);
    else if (isSamsungDevice) setPhoneVendor(PhoneVendors.SAMSUNG);
  }, []);

  return phoneVendor;
}

export default useVendorDetection;
