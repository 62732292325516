import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import Modal from "react-bootstrap/Modal";
import Image from "react-bootstrap/Image";
import Col from "react-bootstrap/Col";
import Copy from "../../images/copy.svg";
import "./DontForget.scss";

type IDontForgetProps = {
  show: boolean,
  setShow: Dispatch<SetStateAction<boolean>>,
  code: string,
  username?: string
}

const DontForget = ({ show, setShow, code, username } : IDontForgetProps) => {
  const [showTootip, setShowTootip] = useState<boolean>(false);
  const copy = useRef(null);
  
  const handleClose = () => {
    setShow(false);
  };

  const onCopyClick = () => {
    navigator.clipboard.writeText(code);
    setShowTootip(true);

    const tO = setTimeout(() => {
      setShowTootip(false);
      clearTimeout(tO);
    }, 2000);
  }

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
      className="DontForget"
      keyboard={false}
    >
      <Modal.Header closeButton className="border-0"/>
      <Modal.Body className="px-3 text-center">
        <h5 className="pb-4">🚨 DON’T FORGET</h5>
        <p>Use {username && `@${username}'s `}code when you sign up</p>
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <Col className="code flex-grow-0 py-2 px-3">{code}</Col>
          <Col className="my-3 copy px-3 py-1 d-flex align-items-center" onClick={onCopyClick} ref={copy}>
            <span className="px-2">Tap to copy code</span>
            <Image src={Copy} alt="Copy"/>
          </Col>
          <Overlay target={copy.current} show={showTootip} placement="top">
            {(props) => (
              <Tooltip id="overlay-example" {...props}>
                Copied!
              </Tooltip>
            )}
          </Overlay>
        </Col>
      </Modal.Body>
    </Modal>
  )
}

export default DontForget;