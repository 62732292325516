const faqs = [
  {
    title: "Can I download Hyper from Google Playstore or Apple app store?",
    body: "<p>Hyper is currently not available on the Google Playstore and Apple app store.</p>"
  },
  {
    title: "What is HPR?",
    body: `<p>HPR is Hyper’s in-game currency that has direct money value!</p> <p>There are two ways to get HPR:</p>
        <ol>
          <li>Top-up HPR with USD: you can top-up your HPR balance in your Hyper wallet using USD. To do this, you will need to fund your USD balance in your Hyper wallet first, through crypto or your local currency. </li>
          <li>Earn HPR from Battle Games: you can play battle games against other players using your HPR and GEM rewards and get more HPR as a reward when you win!</li>
        </ol>`
  },
  {
    title: "What is GEM?",
    body: `<p>GEM is Hyper’s reward currency that is rewarded to you for free! when you sign up, maintain game streaks, and refer friends</p>
           <p>You can earn GEM in three ways: </p>
        <ol>
          <li>Sign Up: we reward you with 10 GEM when you sign up and create your account so that you can start playing and winning!</li>
          <li>Maintain Daily Streaks: play games daily to maintain your gaming streak and earn new GEM rewards every day!</li>
          <li>Refer Friends: share hyper with your friends and get 10 GEM for every successful referral and get 100 Bonus GEM when 5 of your friends sign up within a week!</li>
        </ol>`
  },
  {
    title: "What is a Battle game?",
    body: `<p>In Hyper, there are two gaming modes: Battle and Practice.</p>
           <p>In the Battle mode, you can play with HPR or GEM against other players and earn more HPR when you win!</p>
           <p>You will be matched randomly with another player when you enter a battle game. (In the future, we will also have the option to invite and challenge your friends!)</p>
          `
  },
  {
    title: "What is a Practice Game?",
    body: `<p>In Hyper, there are two gaming modes: Battle and Practice.</p>
           <p>In the Practice mode, you do not need to use any HPR or GEM. You can play risk-free for fun, earn streak rewards and level up your skills!</p>
          `
  },
  {
    title: "Do I need an internet  connection to play games?",
    body: `<p>Yes. To play the games on Hyper you need an internet connection.</p>`
  }
];

export default faqs;