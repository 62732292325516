import React, { useEffect, useMemo, useRef, useState } from "react";
import QrCodeStyling, {
  DrawType,
  TypeNumber,
  Mode,
  ErrorCorrectionLevel,
  DotType,
  CornerSquareType,
  CornerDotType,
  Options
} from "qr-code-styling";
import "./QrCode.scss";

type IQrCodeProps = {
  value: string;
  customOptions?: Options;
  className?: string;
}

function QrCode({ value, customOptions, className }: IQrCodeProps) {
  const [defaultOptions, setDefaultOptions] = useState<Options>({
    width: 100,
    height: 100,
    type: 'svg' as DrawType,
    data: value,
    image: '/favicon.ico',
    margin: 2,
    qrOptions: {
      typeNumber: 0 as TypeNumber,
      mode: 'Byte' as Mode,
      errorCorrectionLevel: 'Q' as ErrorCorrectionLevel
    },
    imageOptions: {
      hideBackgroundDots: true,
      imageSize: 0.4,
      margin: 5,
      crossOrigin: 'anonymous',
    },
    dotsOptions: {
      color: '#222222',
      type: 'rounded' as DotType
    },
    backgroundOptions: {
      color: '#FFF',
    },
    cornersSquareOptions: {
      color: '#222222',
      type: 'extra-rounded' as CornerSquareType,
    },
    cornersDotOptions: {
      color: '#222222',
      type: 'dot' as CornerDotType,
    }
  });

  const qrCodeOptions = useMemo(() => (
    { ...defaultOptions, ...customOptions }
  ), [defaultOptions, customOptions]);

  const [qrCode] = useState<QrCodeStyling>(new QrCodeStyling(qrCodeOptions));
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    if (!qrCode) return;
    qrCode.update(qrCodeOptions);
  }, [qrCode, qrCodeOptions]);

  useEffect(() => {
    setDefaultOptions((opts) => ({ ...opts, data: value }));
  }, [value]);

  return (
    <div className={`QrCode px-0 ${className || ""}`}>
      <div ref={ref} style={{ height: customOptions?.height }} />
    </div>
  );
}

export default QrCode;