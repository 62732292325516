import React from "react";
import { Row, Col, CloseButton } from "react-bootstrap";
import "./Lightbox.scss";

interface LightboxProps {
  src: string;
  title: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
const Lightbox = ({ src, title, isOpen, setIsOpen }: LightboxProps) => {
  return (
    <>
      {isOpen && (
        <Row className="MobileOnly Lightbox d-flex position-fixed flex-column justify-content-start">
          <Col className="flex-grow-0 py-3 d-flex justify-content-end px-4">
            <CloseButton variant="white" onClick={() => setIsOpen(false)} />
          </Col>
          <Col className="w-100 flex-grow-0 px-4">
            <iframe
              className="w-100"
              src={src}
              title={title}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default Lightbox;
