import React from "react";
import { Container, Image, type ContainerProps } from "react-bootstrap";

import WarningAlertCircleOrange from "../../images/warning_alert_circle_orange.svg";
import "./HyperWarningPill.scss";

type IHyperWarningPillProps = {
  textClass: string;
  text: string;
} & ContainerProps;

function HyperWarningPill({
  textClass,
  text,
  ...restProps
}: IHyperWarningPillProps) {
  return (
    <Container className="referral-code-ower-pill" {...restProps}>
      <Image
        src={WarningAlertCircleOrange}
        alt="warning_circle"
        className="pill-icon"
      />
      <span className={` ${textClass} pill-text`}>{text}</span>
    </Container>
  );
}

export default HyperWarningPill;
