import React, { useEffect } from "react";
import { OAuthProvider } from "@nestcoinco/hyper-api-gateway-api-client";

import CheckMarkSuccessful from "../../../images/checkmark_successful.svg";
import DefaultOnboardingStatusPage from "../../ReusablePages/DefaultOnboardingStatusPage/DefaultOnboardingStatusPage";
import {
  getCurrentUrl,
  registerIdentityEvent,
  registerTrackingEvent,
} from "../../../utils/helpers";
import { getStoredAuthProvider, getStoredUser } from "../../../utils/storage";
import { ROUTES, TRACKING_EVENTS } from "../../../utils/constants";

const redirectPath = `${ROUTES.ROOT.ONBOARDING}/${ROUTES.ONBOARDING.REFERRAL_CODE}`;

function SignUpSuccessfulPage() {
  const user = getStoredUser()?.user;
  const authProvider = getStoredAuthProvider();

  useEffect(() => {
    if (!user || !authProvider) return;
    registerTrackingEvent({
      name: TRACKING_EVENTS.SUCCESSFUL_WEB_SIGNUP,
      payload: {
        user_id: user.id,
        email: user.email,
        url: getCurrentUrl(),
      },
    });
    registerTrackingEvent({
      name:
        authProvider === OAuthProvider.GOOGLE
          ? TRACKING_EVENTS.WEB_SIGN_UP_WITH_GOOGLE
          : TRACKING_EVENTS.WEB_SIGN_UP_WITH_FACEBOOK,
      payload: {
        user_id: user.id,
        email: user.email,
        url: getCurrentUrl(),
      },
    });

    registerIdentityEvent(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      username: user.username,
    });
  }, [authProvider, user]);

  return (
    <DefaultOnboardingStatusPage
      titleText="Sign up successful"
      secondaryText="You have successfully signed up to Hyper!"
      primaryImage={CheckMarkSuccessful}
      metaData={{
        title: "Sign Up Successful | Hyper by Metaverse Magna",
        description:
          "You have successfully signed up on Hyper. Start playing now to earn cash",
      }}
      redirectDelay={3000}
      redirectPath={redirectPath}
    />
  );
}

export default SignUpSuccessfulPage;
