import {
  ReferrersReferralsClient,
  CreateReferralDto,
} from "@nestcoinco/hyper-api-gateway-api-client";

import apiConfig from "../config/api-config";
import { axiosInstance } from "../config/axios-instance";

const referralsClient = new ReferrersReferralsClient(
  apiConfig,
  undefined,
  axiosInstance
);

const handleGetReferrerInfo = async (referrerId: string) => {
  try {
    const { data } = await referralsClient.getReferrerInfo(referrerId);
    return data;
  } catch {
    return;
  }
};

const handleCreateReferral = async (body: CreateReferralDto) => {
  try {
    const { data } = await referralsClient.initiateReferral(body);
    return data;
  } catch {
    return;
  }
};

export { handleGetReferrerInfo, handleCreateReferral };
