import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async';
import PaymentStatus, { PaymentStatusType } from '../../components/Payments/PaymentStatus/PaymentStatus';
import EventDispatcher from '../../utils/event-dispatcher';
import { EventTypes } from '../../utils/constants';
import { AppIntentValues } from '../../utils/constants/events-types';

function TopupPending() {
  useEffect(() => {
    EventDispatcher.dispatch(EventTypes.PAYMENTS.FAILED, { message: "Payment failed" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Topup Pending | Hyper by Metaverse Magna</title>
        <meta name="og:title" content="Topup Pending | Hyper by Metaverse Magna"/>
      </Helmet>

      <PaymentStatus
        type={PaymentStatusType.DONE}
        title="Payment pending"
        message="Your payment may not have been completed or it could not be confirmed."
        eventType={EventTypes.PAYMENTS.ERROR}
        navigateUrl={AppIntentValues.PAYMENT_PENDING}
      />
    </>
  )
}

export default TopupPending;