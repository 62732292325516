import React from "react";
import { Helmet } from "react-helmet-async";
import MetaImage from "../images/meta-og-image.png";

export function renderHeaders({
  title,
  description,
  image,
}: {
  title: string;
  description: string;
  image?: string;
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="og:title" content={title} />
      <meta name="og:description" content={description} />
      <meta name="og:image" content={image || MetaImage} />
    </Helmet>
  );
}
