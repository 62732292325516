import React from "react";
import Spinner, { type SpinnerProps } from "react-bootstrap/Spinner";

function CustomSpinner(props: SpinnerProps) {
  return (
    <Spinner animation="border" role="status" {...props}>
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

export { CustomSpinner };
