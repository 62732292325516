import React, { useState } from "react";

import CircularButton from "../../Buttons/CircularButton/CircularButton";
import ImageUserIcon from "../../../images/user-filled.png";
import EditImageIcon from "../../../images/edit_image_icons.svg";
import { useUsernamePageContext } from "../../../context/username-page-context";
import AvatarModal from "../AvatarModal/AvatarModal";

function AvatarSection() {
  const [isOpen, setIsOpen] = useState(false);
  const { profile } = useUsernamePageContext();

  const handleOpenSelector = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <section className="change-user-name-image-container">
      <CircularButton
        color="#DCD3FD"
        image={EditImageIcon}
        buttonClass="user-image-edit-class-button"
        onClick={handleOpenSelector}
      />
      <CircularButton
        color="#DCD3FD"
        image={profile?.avatar?.url || ImageUserIcon}
        buttonClass="user-image-circular-container-class-button"
        imageClass="profile-avatar"
        onClick={handleOpenSelector}
      />
      <AvatarModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </section>
  );
}

export default AvatarSection;
