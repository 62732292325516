import React, { ComponentProps } from "react";

function DownArrowIcon(props: ComponentProps<"svg">) {
  return (
    <svg
      width={13}
      height={8}
      viewBox="0 0 13 8"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="chevron.down">
        <g id="Group">
          <path
            id="Vector"
            d="M6.22082 7.90566C6.44848 7.90446 6.65448 7.81703 6.82374 7.63914L11.9224 2.41568C12.0688 2.26929 12.1478 2.08822 12.1478 1.87621C12.1478 1.43881 11.8083 1.09381 11.3745 1.09381C11.1625 1.09381 10.9651 1.17885 10.8139 1.32818L5.89295 6.37503H6.54802L1.62223 1.32818C1.47532 1.18126 1.27911 1.09381 1.06041 1.09381C0.62541 1.09381 0.287109 1.43881 0.287109 1.87621C0.287109 2.08702 0.367332 2.26809 0.511304 2.42117L5.61238 7.63914C5.79265 7.81943 5.98885 7.90566 6.22082 7.90566Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default DownArrowIcon;
