import React, { useState } from "react";
import "./CollapsibleList.scss";

interface ICollapsibleItem {
  title: string;
  body: string;
}

interface ICollapsibleListProps {
  items: Array<ICollapsibleItem>
  className?: string;
}

function CollapsibleList ({ items = [], className = "" }: ICollapsibleListProps) {
  const [expandedIndex, setExpandedIndex] = useState(0);

  const handleDropdown = (index: number) => {
    if (index === expandedIndex) {
      setExpandedIndex(-1);
      return;
    }
    setExpandedIndex(index);
  }

  return (
    <div className={`CollapsibleList d-flex flex-column ${className}`}>
      {items?.length?
        items.map((item, i) => (
          <button
            key={`collapsible-list-${i}`}
            onClick={() => handleDropdown(i)}
            className={`p-4 text-start item ${expandedIndex === i && "active"}`}
          >
            <p className="fs-5 title">{item.title}</p>
            <div className="fs-6 body text-base" dangerouslySetInnerHTML={{__html: item.body}}/>
          </button>
        )):
        null
      }
    </div>
  )
}

export default CollapsibleList;