export enum PaymentEventTypes {
  INITIATED = "hyper.payment.initiated",
  SUCCESSFUL = "hyper.payment.successful",
  FAILED = "hyper.payment.failed",
  ERROR = "hyper.payment.error",
}

export enum AppIntentValues {
  HOME = "app://hyper/home",
  WALLETS = "app://hyper/wallets",
  PAYMENT_PENDING = "app://hyper/wallets/pending",
  PAYMENT_FAILED = "app://hyper/wallets/failed",
  PAYMENT_SUCCESSFUL = "app://hyper/wallets/successful",
}