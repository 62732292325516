import React, { useEffect, useState } from "react";
import { Container, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import OnboardingHeader from "../../../components/Headers/OnboardingHeader/OnboardingHeader";
import HyperTextField from "../../../components/TextInputAreas/HyperTextField/HyperTextField";
import HyperWarningPill from "../../../components/Pill/HyperWarningPill";
import PrimaryActionButton from "../../../components/Buttons/PrimaryActionButton/PrimaryActionButton";
import "./CreateUserNamePage.scss";
import {
  handleGetProfile,
  handleUpdateOnboardingState,
  handleUpdateUsername,
} from "../../../api-clients/userAccountClient";
import { CustomSpinner } from "../../../components/CustomSpinner/CustomSpinner";
import { renderHeaders } from "../../../utils/renderHeaders";
import { useUsernamePageContext } from "../../../context/username-page-context";
import { registerTrackingEvent, getCurrentUrl } from "../../../utils/helpers";
import { ROUTES, TRACKING_EVENTS } from "../../../utils/constants";
import { showSuccessToast } from "../../../utils/show-toast";
import AvatarSection from "../../../components/create-username-page/AvatarSection/AvatarSection";
import { setStoredUserProfile } from "../../../utils/storage";

function checkIsUsernameValid(input: string) {
  const regex = /^\w{4,20}$/;
  return regex.test(input);
}
const errorMessage =
  "Username must be between 4-20 characters and contain only alphanumberic characters/underscores";

function CreateUserNamePage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { profile, setProfile } = useUsernamePageContext();
  const [username, setUsername] = useState("");
  const [error, setError] = useState("");
  const isUsernameUnchanged =
    profile?.username && profile.username === username;

  const handleInputChange = (e: React.FormEvent<HTMLTextAreaElement>) => {
    if (checkIsUsernameValid(e.currentTarget.value)) {
      error && setError("");
    }
    setUsername(e.currentTarget.value);
  };

  async function handleOnContinueClicked() {
    const nextPagePath = `${ROUTES.ROOT.ONBOARDING}/${ROUTES.ONBOARDING.WELCOME}`;
    if (!checkIsUsernameValid(username)) {
      setError(errorMessage);
      return;
    }
    setError("");
    profile &&
      registerTrackingEvent({
        name: TRACKING_EVENTS.CREATE_WEB_USERNAME,
        payload: {
          user_id: profile.id,
          email: profile.email,
          username: profile.username,
          url: getCurrentUrl(),
        },
      });
    await handleUpdateOnboardingState({ claimedUsername: true });
    if (isUsernameUnchanged) {
      showSuccessToast("Username Claimed", { top: -4 });
      navigate(nextPagePath);
      return;
    }
    setIsLoading(true);
    const userProfile = await handleUpdateUsername(username);
    setIsLoading(false);
    if (!userProfile) return;
    setStoredUserProfile(userProfile);
    showSuccessToast("Username Claimed", { top: -4 });
    navigate(nextPagePath);
  }

  useEffect(() => {
    const getProfile = async () => {
      setIsLoading(true);
      const userProfile = await handleGetProfile();
      setIsLoading(false);
      if (!userProfile) return;
      setProfile(userProfile);
      setUsername(userProfile.username);
      setError("");
    };

    getProfile();
  }, [setProfile]);

  return (
    <>
      {renderHeaders({
        title: "Create Username | Hyper by Metaverse Magna",
        description: "Create your gamer username to start playing",
      })}
      <Container className="px-3 MobileOnly position-relative create-user-name-page">
        <Col className="d-flex">
          <Col className="create-user-name-header">
            <OnboardingHeader amountActive={4} />
          </Col>
          <Col className="flex-column create-user-name-content">
            <h1 className="text-white page-title my-0">Claim Username</h1>
            <p className="username-description mb-3">
              Your username is your identity during a game challenge. Create
              your legacy!
            </p>
            <Container className="d-flex justify-content-center">
              <AvatarSection />
            </Container>
            <div className="mt-4">
              <HyperTextField
                hint="Username"
                onChange={handleInputChange}
                value={username}
              />
            </div>
            {error && (
              <p className="error-text" role="alert">
                {error}
              </p>
            )}
            <div className="mt-2">
              <HyperWarningPill
                textClass="pill-text"
                text="You can make changes to your avatar and username anytime"
              />
            </div>
            <div className="mt-4 username-cta MobileOnly px-lg-3">
              <PrimaryActionButton
                textColor={"#FFFFFF"}
                textClassName="create-btn"
                text={
                  isLoading ? (
                    <>
                      Creating Username <CustomSpinner size="sm" />
                    </>
                  ) : (
                    "Create Username"
                  )
                }
                onClick={handleOnContinueClicked}
                imgClassName="mb-1 mx-2"
                disabled={isLoading}
              />
            </div>
          </Col>
        </Col>
      </Container>
    </>
  );
}

export default CreateUserNamePage;
