import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  collection,
  getDocs,
  limit,
  query,
  where,
} from "firebase/firestore/lite";
import VerificationInput from "react-verification-input";

import PrimaryActionButton from "../../../components/Buttons/PrimaryActionButton/PrimaryActionButton";
import OnboardingHeader from "../../../components/Headers/OnboardingHeader/OnboardingHeader";
import "./ReferralCodePage.scss";
import HyperWarningPill from "../../../components/Pill/HyperWarningPill";
import {
  clearStoredReferrerInfo,
  getStoredReferrerInfo,
  getStoredUser,
} from "../../../utils/storage";
import { renderHeaders } from "../../../utils/renderHeaders";
import { db } from "../../../config/firebase";
import {
  FIREBASE_COLLECTIONS,
  ROUTES,
  TRACKING_EVENTS,
} from "../../../utils/constants";
import { toast } from "../../../components/CustomToast/CustomToast";
import {
  handleCreateReferral,
  handleGetReferrerInfo,
} from "../../../api-clients/referralsClient";
import { CustomSpinner } from "../../../components/CustomSpinner/CustomSpinner";
import { registerTrackingEvent, getCurrentUrl } from "../../../utils/helpers";
import { handleUpdateOnboardingState } from "../../../api-clients/userAccountClient";
import ImageGem from "../../../images/gem.svg";

const CODE_LENGTH = 4;
const NEXT_SCREEN_URL = `${ROUTES.ROOT.ONBOARDING}/${ROUTES.ONBOARDING.USERNAME}`;
const storedReferrerInfo = getStoredReferrerInfo();

function ReferralCodePage() {
  const navigate = useNavigate();
  const [referralCode, setReferralCode] = useState(
    storedReferrerInfo?.code || ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const storedUser = getStoredUser();
  const isReferralCodeComplete = referralCode.length === CODE_LENGTH;
  const referrerUsername = storedReferrerInfo?.username;

  const pillText = referrerUsername
    ? `You're using @${referrerUsername}'s referral code`
    : "Referral code is case sensitive. Make sure to confirm codes.";

  const handleSubmitCode = async () => {
    if (!storedUser) return;
    setIsLoading(true);
    const referrerId = await getReferrerIdFromCode(referralCode);
    if (!referrerId) {
      setIsLoading(false);
      return;
    }
    const referrerInfo = await handleGetReferrerInfo(referrerId);
    if (!referrerInfo) {
      setIsLoading(false);
      return;
    }
    const createdReferral = await handleCreateReferral({
      referrerId,
      referentId: storedUser.user.id,
    });

    setIsLoading(false);
    clearStoredReferrerInfo();
    if (!createdReferral) return;
    await handleUpdateOnboardingState({ usedReferralCode: true });
    registerTrackingEvent({
      name: TRACKING_EVENTS.WEB_SUBMIT_REFERRAL_CODE,
      payload: {
        user_id: storedUser.user.id,
        email: storedUser.user.email,
        referral_username: referrerInfo.username,
        referral_userID: referrerId,
        url: getCurrentUrl(),
      },
    });
    navigate(NEXT_SCREEN_URL);
  };

  const handleSkip = async () => {
    await handleUpdateOnboardingState({ usedReferralCode: true });
    registerTrackingEvent({
      name: TRACKING_EVENTS.SKIP_WEB_REFERRAL,
      payload: {
        user_id: storedUser?.user?.id,
        email: storedUser?.user?.email,
        url: getCurrentUrl(),
      },
    });
    clearStoredReferrerInfo();
    navigate(NEXT_SCREEN_URL);
  };

  return (
    <>
      {renderHeaders({
        title:
          "Use Your Friend's Hyper Referral Code | Hyper by Metaverse Magna",
        description:
          "Do you have a referral code, use it on this page. Get started now",
      })}
      <div className="MobileOnly position-relative referral-code-page">
        <Col className="d-flex">
          <Col className="referral-screen-header">
            <OnboardingHeader amountActive={3} />
          </Col>

          <Col className="flex-column referral-code-page-content">
            <p className="mx-3 text-white page-title">Got a referral code?</p>
            <p className="mx-3 text-white page-description">
              You friend gets rewarded with <Image src={ImageGem} alt="gem" />{" "}
              GEM when you use their referral code.
            </p>
            <Col className="mx-3 pin-text-container">
              <VerificationInput
                length={CODE_LENGTH}
                placeholder=""
                classNames={{
                  container: "character-container",
                  character: "character",
                  characterSelected: "character--selected",
                }}
                value={referralCode}
                onChange={(value) => setReferralCode(value)}
              />
            </Col>
            <HyperWarningPill
              textClass="pill-text"
              text={pillText}
              style={{ margin: "0 16px" }}
            />
            <Col className="referral-code-footer">
              <PrimaryActionButton
                textColor={"#FFFFFF"}
                text={isLoading ? <CustomSpinner /> : "Continue"}
                onClick={handleSubmitCode}
                imgClassName="mb-1 mx-2"
                disabled={!isReferralCodeComplete}
                style={{ marginTop: "16px" }}
              />
              <PrimaryActionButton
                textColor={"#FFFFFF"}
                text="I don't have a code"
                onClick={handleSkip}
                imgClassName="mb-1 mx-2"
                style={{ background: "#191040", marginTop: "16px" }}
              />
            </Col>
          </Col>
        </Col>
      </div>
    </>
  );
}

export default ReferralCodePage;

async function getReferrerIdFromCode(referralCode: string) {
  const q = query(
    collection(db, FIREBASE_COLLECTIONS.referral),
    where("code", "==", referralCode),
    limit(1)
  );

  try {
    const querySnapshot = await getDocs(q);
    if (querySnapshot.empty) {
      toast({ toastMessage: "Invalid referral code", variant: "error" });
      return null;
    }

    const doc = querySnapshot.docs[0];
    const referral = doc.data();
    const longlink: string | undefined = referral.longLink;

    if (!longlink || !longlink.includes("referrer=")) {
      return null;
    }

    const referrerId = longlink.split("referrer=")[1];
    return referrerId;
  } catch (e) {
    console.error("Error:", e);
    return null;
  }
}
