import Withdrawal  from "../images/withdrawal.svg";
import Bonus  from "../images/bonus.svg";
import Secure  from "../images/secure.svg";
import Support  from "../images/support.svg";

const whyPlay: Array<Record<string, string>> = [
  {
    key: "withdrawal",
    image: Withdrawal,
    copy: "Instant",
    subCopy: "Withdrawals",
  },
  {
    key: "bonus",
    image: Bonus,
    copy: "2X bonus",
    subCopy: "on 1st deposit",
  },
  {
    key: "secure",
    image: Secure,
    copy: "100% Safe",
    subCopy: "& Secure",
  },
  {
    key: "support",
    image: Support,
    copy: "24 x 7",
    subCopy: "Support",
  },
];

export default whyPlay