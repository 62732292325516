import React from "react";
import { HelmetProvider } from "react-helmet-async";
import Container from "react-bootstrap/Container";
import { RouterProvider } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import router from "./router";
import "./App.scss";
import PaymentConfirmationProvider from "./components/Payments/PaymentConfirmationProvider";

function App() {
  return (
    <HelmetProvider>
      <PaymentConfirmationProvider>
        <Container fluid as="main" className="App p-0">
          <RouterProvider router={router} />
          <Toaster toastOptions={{ duration: 3000 }} />
        </Container>
      </PaymentConfirmationProvider>
    </HelmetProvider>
  );
}

export default App;
