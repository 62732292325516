import React from "react";
import { ComponentProps } from "react";

import "./FilledButton.scss";

export enum ButtonSizes {
  SM = "small",
  MD = "medium",
}

interface FilledButtonProps extends ComponentProps<"button"> {
  size?: ButtonSizes;
  fullwidth?: boolean;
}

function FilledButton({
  children,
  className = "",
  size = ButtonSizes.SM,
  fullwidth,
  ...restProps
}: FilledButtonProps) {
  return (
    <button
      className={`filled-btn ${size === ButtonSizes.MD ? "btn-md" : "btn-sm"} ${
        fullwidth ? "w-100" : ""
      } ${className}`}
      {...restProps}
    >
      {children}
    </button>
  );
}

export default FilledButton;
